import { AppLayout } from '../../../themes';
import React from 'react';
import UnderConstructions from '../../UnderConstructions';

const DefaultDashboardPage = () => {
	return (
		<AppLayout pageTitle='Dashboard'>
			<UnderConstructions />
		</AppLayout>
	);
};

export default DefaultDashboardPage;
