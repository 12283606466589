import BodiesPage from './BodiesPage';
import CreateBodyPage from './CreateBodyPage';
import UpdateBodyPage from './UpdateBodyPage';

export const BodiesRoutes = [
	{ path: '', element: <BodiesPage /> },
	{
		path: 'create',
		element: <CreateBodyPage />,
	},
	{
		path: 'update/:id',
		element: <UpdateBodyPage />,
	},
];
