import { Button, Card, Form, Input, Select, Spin, Switch, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from 'react-query';

import { ApiServices } from '../../services/api.service';
import { AppLayout } from '../../themes/AppLayout';
import { RentCountPer } from '../../enums';
import UploadImage from '../../components/UploadImage';
import qs from 'qs';
import { queryClient } from '../../config';
import { useBodiesQuery } from '../../services/quries';
import { useNavigate } from 'react-router';
import { useState } from 'react';

const CreateCarsPage = () => {
	const navigate = useNavigate();
	const createCarMutation = useMutation(ApiServices.createCar, {
		onSuccess: (data) => {
			message.success('Car created successfully');
			navigate('/cars');
			queryClient.invalidateQueries(`${ApiServices.filterCar.name}`);
		},
	});
	const [brandSearchTerm, setBrandSearchTerm] = useState('');
	const brandQuery = useQuery({
		queryKey: [
			`${ApiServices.filterBrand.name}`,
			qs.stringify({ searchTerm: brandSearchTerm }),
			1231232,
		],
		queryFn: () =>
			ApiServices.filterBrand({
				searchTerm: brandSearchTerm,
				isActive: true,
				page: 1,
				take: 10,
			}),
	});
	const [userSearchTerm, setUserSearchTerm] = useState('');
	const usersQuery = useQuery({
		queryKey: [
			`${ApiServices.filterUsers.name}`,
			qs.stringify({ searchTerm: userSearchTerm }),
			'123fsadfds1232',
		],
		queryFn: () =>
			ApiServices.filterUsers({
				searchTerm: userSearchTerm,
				isActive: true,
				page: 1,
				take: 10,
			}),
	});
	const [categorySearchTerm, setCategorySearchTerm] = useState('');
	const categoriesQuery = useQuery({
		queryKey: [
			`${ApiServices.filterCategories.name}`,
			qs.stringify({ searchTerm: categorySearchTerm }),
			123312321321534543,
		],
		queryFn: () =>
			ApiServices.filterCategories({
				searchTerm: categorySearchTerm,
				isActive: true,
				page: 1,
				take: 10,
			}),
	});
	const [bodySearchTerm, setBodySearchTerm] = useState('');
	const bodyQuery = useBodiesQuery({
		options: {
			searchTerm: bodySearchTerm,
			isActive: true,
			page: 1,
			take: 10,
		},
	});
	const [form] = Form.useForm();
	const onFinish = (values: any) => {
		console.log('🚀 ~ file: CreateCarsPage.tsx:80 ~ onFinish ~ values:', {
			values,
			images,
			coverImage,
		});
		if (!coverImage) {
			message.error('Please upload cover image');
			return;
		}
		if (images.length === 0) {
			message.error('Please upload images');
			return;
		}
		const payload = {
			body: values.body.value,
			brand: values.brand.value,
			category: values.category.value,
			description: values.description,
			facilities: values.facilities,
			coverImage: coverImage,
			isFeatured: values.isFeatured,
			licenseNo: values.licenseNo,
			model: values.model,
			owner: values.owner.value,
			rentAmount: Number(values.rentAmount),
			rentCountPer: values.rentCountPer,
			rentCountPerMinimum: values.rentCountPerMinimum,
			title: values.title,
			images: images.map((image) => image),
			dropOffLocation: values.dropOffLocation,
			pickupLocation: values.pickupLocation,
		};
		console.log({ payload });
		createCarMutation.mutate(payload);
	};
	const [images, setImages] = useState([]);
	const [coverImage, setCoverImage] = useState(null);
	return (
		<AppLayout pageTitle='Create new Car' loading={createCarMutation.isLoading}>
			<Card>
				<Form
					form={form}
					layout='vertical'
					name='basic'
					initialValues={{
						title: '',
						description: '',
						rentCountPer: '',
						rentAmount: '',
						rentCountPerMinimum: '',
						licenseNo: '',
						category: '',
						brand: '',
						owner: '',
						body: '',
						model: '',
						pickupLocation: '',
						dropOffLocation: '',
						facilities: [],
						isActive: false,
						isFeatured: false,
					}}
					onFinish={onFinish}
					autoComplete='off'
				>
					<div className='grid grid-cols-8 gap-6'>
						<div className='col-span-5'>
							<Form.Item
								label='Title'
								name='title'
								tooltip='Car title Which will be shown in the app'
								rules={[{ required: true, message: 'Please input title!' }]}
							>
								<Input size='large' />
							</Form.Item>
							<Form.Item
								label='Description'
								name='description'
								tooltip='Car description Which will be shown in the app car details page'
								rules={[
									{ required: true, message: 'Please input description!' },
								]}
							>
								<Input.TextArea rows={6} size='large' />
							</Form.Item>
							<Form.Item
								label='Pickup Location'
								name='pickupLocation'
								tooltip='Car pickupLocation Which will be shown in the app car details page'
								rules={[
									{ required: true, message: 'Please input pickupLocation!' },
								]}
							>
								<Input size='large' />
							</Form.Item>
							<Form.Item
								label='DropOff Location'
								name='dropOffLocation'
								tooltip='Car dropOffLocation Which will be shown in the app car details page'
								rules={[
									{ required: true, message: 'Please input dropOffLocation!' },
								]}
							>
								<Input size='large' />
							</Form.Item>
							<Form.Item
								tooltip='How you want to count the rent amount? per day or per hour?'
								label='Rent Count Per'
								name='rentCountPer'
								rules={[
									{
										required: true,
										message: 'Please input rent counter per day!',
									},
								]}
							>
								<Select size='large' placeholder='Select Rent Count Per'>
									<Select.Option value={RentCountPer.DAILY}>
										{RentCountPer.DAILY}
									</Select.Option>
									<Select.Option value={RentCountPer.HOURLY}>
										{RentCountPer.HOURLY}
									</Select.Option>
								</Select>
							</Form.Item>
							<Form.Item
								label='Rent Amount'
								name='rentAmount'
								tooltip='Rent amount per day or per hour which you choose above and it will be shown in the app car details page'
								rules={[
									{
										required: true,
										message: 'Please input rentAmount!',
									},
								]}
							>
								<Input size='large' />
							</Form.Item>
							<Form.Item
								label='Rent Count Per Minimum'
								name='rentCountPerMinimum'
								tooltip='Rent count per minimum which will validate when user try to rent the car'
								rules={[
									{
										required: true,
										message: 'Please input rentCountPerMinimum!',
									},
								]}
							>
								<Input size='large' />
							</Form.Item>
							<Form.Item
								label='License No'
								name='licenseNo'
								rules={[
									{ required: true, message: 'Please input  license no!' },
								]}
							>
								<Input size='large' />
							</Form.Item>
							<Form.Item
								label='model'
								name='model'
								rules={[{ required: true, message: 'Please input  model no!' }]}
							>
								<Input size='large' />
							</Form.Item>
							<Form.Item
								name='category'
								label='Category'
								rules={[{ required: true }]}
							>
								<Select
									labelInValue
									size='large'
									searchValue={categorySearchTerm}
									showSearch
									filterOption={false}
									onSearch={(e) => {
										setCategorySearchTerm(e);
									}}
									notFoundContent={
										categoriesQuery.isLoading ? <Spin size='small' /> : null
									}
									options={categoriesQuery.data?.data?.payload?.map((item) => ({
										label: item.title,
										value: item.id,
									}))}
								/>
							</Form.Item>
							<Form.Item
								name='brand'
								label='Brand'
								rules={[{ required: true }]}
							>
								<Select
									labelInValue
									size='large'
									searchValue={brandSearchTerm}
									showSearch
									filterOption={false}
									onSearch={(e) => {
										setBrandSearchTerm(e);
									}}
									notFoundContent={
										brandQuery.isLoading ? <Spin size='small' /> : null
									}
									options={brandQuery.data?.data?.payload?.map((item) => ({
										label: item.title,
										value: item.id,
									}))}
								/>
							</Form.Item>
							<Form.Item
								name='owner'
								label='Owner'
								rules={[{ required: true }]}
							>
								<Select
									labelInValue
									size='large'
									searchValue={userSearchTerm}
									showSearch
									filterOption={false}
									onSearch={(e) => {
										setUserSearchTerm(e);
									}}
									notFoundContent={
										usersQuery.isLoading ? <Spin size='small' /> : null
									}
									options={usersQuery.data?.data?.payload?.map((item) => ({
										label: item?.firstName + ' ' + item?.lastName,
										value: item.id,
									}))}
								/>
							</Form.Item>
							<Form.Item
								name='body'
								label='Choose Body'
								rules={[{ required: true }]}
							>
								<Select
									labelInValue
									size='large'
									searchValue={bodySearchTerm}
									showSearch
									filterOption={false}
									onSearch={(e) => {
										setBodySearchTerm(e);
									}}
									notFoundContent={
										bodyQuery.isLoading ? <Spin size='small' /> : null
									}
									options={bodyQuery.data?.data?.payload?.map((item) => ({
										label: item.title,
										value: item.id,
									}))}
								/>
							</Form.Item>
							<div className='col-span-full'>
								<Form.List
									name='facilities'
									rules={[
										{
											validator: async (_, names) => {
												if (!names || names.length < 2) {
													return Promise.reject(
														new Error('At least 1 facility is required')
													);
												}
											},
										},
									]}
								>
									{(fields, { add, remove }, { errors }) => (
										<>
											{fields.map((field, index) => (
												<Form.Item
													label={index === 0 ? 'Facilities' : ''}
													required={false}
													key={field.key}
												>
													<Form.Item
														{...field}
														validateTrigger={['onChange', 'onBlur']}
														rules={[
															{
																required: true,
																whitespace: true,
																message:
																	"Please input Facilities's name or delete this field.",
															},
														]}
														noStyle
													>
														<Input size='large' placeholder='Facilities name' />
													</Form.Item>
													{fields.length > 1 ? (
														<MinusCircleOutlined
															className='dynamic-delete-button'
															onClick={() => remove(field.name)}
														/>
													) : null}
												</Form.Item>
											))}
											<Form.Item>
												<Button
													type='dashed'
													size='large'
													onClick={() => add()}
													style={{ width: '100%' }}
													icon={<PlusOutlined />}
												>
													Add Facilities
												</Button>
												<Form.ErrorList errors={errors} />
											</Form.Item>
										</>
									)}
								</Form.List>
							</div>
							<div className='flex flex-wrap gap-2'>
								<Form.Item
									label='Status'
									name='isActive'
									valuePropName='checked'
								>
									<Switch />
								</Form.Item>
								<Form.Item
									label='isFeatured'
									name='isFeatured'
									valuePropName='checked'
								>
									<Switch />
								</Form.Item>
							</div>
						</div>
						<div className=' space-y-6 col-span-3'>
							<UploadImage
								title='Upload Car Cover Image'
								onChanged={(file: any[]) => {
									console.log(file);
									setCoverImage(file[0]);
								}}
							/>
							<UploadImage
								multiple
								maxCount={5}
								title='Upload Car Images'
								onChanged={(file: any[]) => {
									setImages(file);
								}}
							/>
						</div>
					</div>
					<Form.Item>
						<Button
							loading={createCarMutation.isLoading}
							type='primary'
							htmlType='submit'
						>
							Submit
						</Button>
						<Button
							className='ml-2'
							type='primary'
							danger
							onClick={() => {
								form.resetFields();
							}}
						>
							Reset
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</AppLayout>
	);
};
export default CreateCarsPage;
