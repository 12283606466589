import './style.scss';

import {
	AiOutlineAim,
	AiOutlineDashboard,
	AiOutlineMenuFold,
	AiOutlineMenuUnfold,
	AiOutlineUser,
	AiTwotoneAppstore,
	AiTwotoneCar,
} from 'react-icons/ai';
import { Dropdown, Layout, Menu, MenuProps, Spin } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';

import { ApiServices } from '../services/api.service';
import { BsCardChecklist } from 'react-icons/bs';
import { DownOutlined } from '@ant-design/icons';
import packageJson from '../../package.json';
import { storage } from '../utils';
import { useAuth } from '../features/auth';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useResponsive } from 'ahooks';
const { Header, Content, Sider } = Layout;
interface IFProps {
	children: ReactNode;
	pageTitle?: string;
	loading?: boolean;
}
export const AppLayout: React.FC<IFProps> = ({
	children,
	pageTitle,
	loading,
}) => {
	const responsive = useResponsive();
	const { logoutFn } = useAuth();
	const isMobileView = responsive.lg === false;
	const [sideBarCollapse, setSideBarCollapse] = useState(false);
	const navigation = useNavigate();
	useEffect(() => {
		if (isMobileView) {
			setSideBarCollapse(true);
		}
		// eslint-disable-next-line
	}, []);
	const id: any = storage.getDecodedToken()?.id;
	const userQuery: any = useQuery({
		queryFn: () => ApiServices.userById(id),
	});
	const name =
		userQuery?.data?.data?.payload?.userInfo?.firstName ||
		userQuery?.data?.data?.payload?.email;
	const items: MenuProps['items'] = [
		{
			key: '4',
			danger: true,
			label: 'Logout',
			onClick: () => {
				logoutFn();
			},
		},
	];
	const pathName = window.location.pathname;
	const MENUS = [
		{
			key: '1',
			label: 'Dashboard',
			icon: <AiOutlineDashboard />,
			onClick: () => navigation('/dashboard'),
		},
		{
			key: '2',
			label: 'Manage Users',
			icon: <AiOutlineUser />,
			children: [
				{
					key: '2-1',
					label: 'Create New User',
					onClick: () => navigation('/users/create'),
				},
				{
					key: '2-2',
					label: 'Users Management',
					onClick: () => navigation('/users'),
				},
			],
		},
		{
			key: '3',
			label: 'Categories',
			icon: <AiTwotoneAppstore />,
			onClick: () => navigation('/category'),
		},
		{
			key: '4',
			label: 'Brands',
			icon: <AiOutlineAim />,
			onClick: () => navigation('/brand'),
		},
		{
			key: '5',
			label: 'Cars',
			icon: <AiTwotoneCar />,
			children: [
				{
					key: '5-1',
					label: 'Create New Car',
					onClick: () => navigation('/cars/create'),
				},
				{
					key: '5-2',
					label: 'Cars Management',
					onClick: () => navigation('/cars'),
				},
			],
		},
		{
			key: '6',
			label: 'Boodies',
			icon: <AiTwotoneCar />,
			children: [
				{
					key: '6-1',
					label: 'Create New Body',
					onClick: () => navigation('/bodies/create'),
				},
				{
					key: '6-2',
					label: 'Body Management',
					onClick: () => navigation('/bodies'),
				},
			],
		},
		{
			key: '7',
			label: 'Reservation',
			icon: <BsCardChecklist />,
			children: [
				{
					key: '7-1',
					label: 'Reservation Management',
					onClick: () => navigation('/reservation'),
				},
			],
		},
		{
			key: '8',
			label: 'Withdrawal',
			icon: <BsCardChecklist />,
			children: [
				{
					key: '8-1',
					label: 'Withdrawal Management',
					onClick: () => navigation('/withdrawal'),
				},
			],
		},
	];
	return (
		<Layout className='winter-layout'>
			<Sider
				trigger={null}
				collapsible
				collapsed={sideBarCollapse}
				className='winter-layout__sider bg-[#001529]'
				style={{
					position: 'absolute',
					left: 0,
					height: '100%',
					background: '#001529',
				}}
				collapsedWidth={0}
				width={200}
			>
				<div className='py-5 flex pl-5 justify-start items-center'>
					<img src={'/logo.png'} className='w-8 h-8 rounded-sm' alt='' />
					<p className='font-medium text-base ml-2 text-white'>FLeat</p>
					<div className='absolute right-4 cursor-pointer lg:hidden'>
						<AiOutlineMenuFold
							onClick={() => {
								setSideBarCollapse(true);
							}}
							className='text-xl text-secondary '
						/>
					</div>
				</div>
				<Menu
					theme='dark'
					mode='inline'
					className='border-none'
					key={'sideBarCollapse'}
					selectedKeys={[pathName]}
					items={MENUS}
				></Menu>
			</Sider>
			<Layout
				className='winter-layout__inner'
				style={{
					marginLeft: isMobileView ? 0 : 200,
				}}
			>
				<Header
					className='header'
					style={{
						marginLeft: isMobileView ? 0 : 200,
					}}
				>
					<div className='header__left'>
						<h3 className='header__sidebar__toggler inline-block lg:hidden'>
							<AiOutlineMenuUnfold
								className='text-xl mr-2 text-secondary cursor-pointer'
								onClick={() => {
									setSideBarCollapse(false);
								}}
							/>
						</h3>
						<h3 className='header__page-title'>{pageTitle}</h3>
					</div>
					<div className='header__right'>
						<div className='header__actions'>
							<Dropdown menu={{ items }}>
								<div className='flex items-center cursor-pointer'>
									<img
										className='header__actions__avater'
										src={userQuery?.userInfo?.image}
										alt=''
									/>
									<h3 className='header__actions_username'>{name}</h3>
									<DownOutlined />
								</div>
							</Dropdown>
						</div>
					</div>
				</Header>
				<Content className='winter-layout__content'>
					<Spin spinning={loading || false}>
						{children}
						<p className='text-white mt-2 fixed bottom-0 left-0 z-40'>
							Version {packageJson?.version}
						</p>
					</Spin>
				</Content>
			</Layout>
		</Layout>
	);
};
