import { Button, Table, TableColumnType } from 'antd';
import React, { useState } from 'react';

import { AppLayout } from '../../themes';
import { ColumnsType } from 'antd/es/table';
import { IBaseFilterRequest } from '../../interfaces/request.interfaces';
import moment from 'moment';
import { toSafeArray } from '../../utils';
import { useFilterWithdrawal } from '../../services/quries';
import { useNavigate } from 'react-router';

const WithDrawalPage = () => {
	const navigate = useNavigate();
	const [options, setOptions] = useState<IBaseFilterRequest>({
		page: 1,
		take: 10,
		searchTerm: '',
		isActive: null,
	});
	const { page, take } = options;
	const withdrawalQuery = useFilterWithdrawal({
		options: options,
	});
	const columns: ColumnsType<any> = [
		{
			title: 'User Name',
			dataIndex: 'name',
			key: 'name',
			render: (name) => name,
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			key: 'phone',
			render: (phone) => phone,
		},
		{
			title: 'Withdrawal Code',
			dataIndex: 'withdrawalCode',
			key: 'withdrawalCode',
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Created At',
			key: 'createdAt',
			dataIndex: 'createdAt',
		},
		{
			title: 'Action',
			key: 'action',
			align: 'right',
			render: (record) => (
				<div className='flex justify-end'>
					<Button
						type='primary'
						className='mr-2 cursor-pointer'
						size='small'
						onClick={() => {
							navigate(`/withdrawal/details/${record?.id}`);
						}}
					>
						View
					</Button>
				</div>
			),
		},
	];
	const data: any[] = toSafeArray(withdrawalQuery?.data?.data?.payload)?.map(
		(item: any) => ({
			key: item?.id,
			id: item?.id,
			name: item?.user?.firstName + ' ' + item?.user?.lastName,
			phone: item?.user?.phone,
			withdrawalCode: item?.withdrawalCode,
			amount: item?.amount,
			status: item?.status,
			createdAt: moment(item?.createdAt).format('DD/MM/YYYY') || 'N/A',
		})
	);
	return (
		<AppLayout pageTitle='WithDrawal'>
			<Table
				columns={columns}
				dataSource={data}
				pagination={{
					current: page,
					pageSize: take,
					total: withdrawalQuery?.data?.data?.total,
					onChange(page, pageSize) {
						setOptions({
							...options,
							page,
							take: pageSize,
						});
					},
				}}
				scroll={{ x: 1300 }}
			/>
		</AppLayout>
	);
};
export default WithDrawalPage;
