import React, { useEffect, useState } from 'react';
import { Spin, Upload } from 'antd';

import { ENV } from '../ENV';
import { IImageItem } from '../interfaces/response.interfaces';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

interface IFProps {
	defaultImages?: IImageItem[];
	onChanged?: (imageUri: IImageItem[]) => void;
	title?: string;
	multiple?: boolean;
	maxCount?: number;
}
const UploadImage: React.FC<IFProps> = ({
	defaultImages,
	onChanged,
	title,
	multiple,
	maxCount,
}) => {
	const [appLoading, setAppLoading] = useState(true);
	const [defaultImagesList, setDefaultImagesList] = useState([]);
	const [fileList, setFileList] = useState(defaultImagesList);
	useEffect(() => {
		const defaultImagesList = defaultImages?.map((image: any) => ({
			uid: uuidv4(),
			name: uuidv4() + '.id',
			status: 'done',
			url: `${image}`,
			urlPath: `${image}`,
			thumbUrl: `${image}`,
			id: image?.id,
			link: image,
		}));
		setDefaultImagesList(defaultImagesList);
		new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
			setAppLoading(false);
		});
	}, [defaultImages]);
	return (
		<div className='flex flex-col'>
			{title && (
				<label
					className='ant-form-item-required inline-block mb-2'
					title='Title'
				>
					{title}
				</label>
			)}
			{appLoading ? (
				<Spin />
			) : (
				<Upload
					accept='image/*'
					onChange={(info) => {
						setFileList(info.fileList);
						const files = info?.fileList
							?.filter((file) => file?.status === 'done')
							?.map((file) => {
								if (file?.originFileObj && file?.response?.payload) {
									return file?.response?.payload?.links[0];
								} else {
									return file?.url;
								}
							});
						console.log({
							files,
							info,
						});
						if (onChanged) onChanged(files);
					}}
					multiple={multiple || false}
					maxCount={maxCount || 1}
					listType='picture-card'
					defaultFileList={defaultImagesList as any}
					action={ENV.CORE_END_POINT + '/images/upload'}
					locale={{ uploading: 'Uploading...' }}
					customRequest={(options: any) => {
						const data = new FormData();
						data.append('files', options.file);
						data.append('type', 'DEFAULT');
						fetch(options.action, {
							method: 'POST',
							body: data,
						})
							.then((res) => res.json())
							.then((res) => {
								options.onSuccess(res, options.file);
							})
							.catch((error) => {
								options.onError(error);
							});
					}}
				>
					{multiple && fileList?.length !== maxCount && (
						<div>
							<PlusOutlined />
							<div style={{ marginTop: 8 }}>Upload</div>
						</div>
					)}
					{!multiple && fileList?.length < 1 && (
						<div>
							<PlusOutlined />
							<div style={{ marginTop: 8 }}>Upload</div>
						</div>
					)}
				</Upload>
			)}
		</div>
	);
};
export default UploadImage;
