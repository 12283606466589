import { Badge, Card, Descriptions, Image, List } from 'antd';

import { AppLayout } from '../../themes';
import React from 'react';
import ShowStatus from '../../utils/util-jsx';
import UpdateWithDrawalStatus from './components/UpdateWithDrawalStatus';
import { currencyFormat } from '../../utils';
import moment from 'moment';
import { useParams } from 'react-router';
import { useWithdrawalDetails } from '../../services/quries';

const WithDrawalDetailsPage = () => {
	const route = useParams();
	const { id } = route;
	const withdrawalDetails = useWithdrawalDetails({
		config: {
			enabled: !!id,
		},
		id: id,
	});
	console.log(
		'🚀 ~ file: WithDrawalDetailsPage.tsx:14 ~ WithDrawalDetailsPage ~ withdrawalDetails:',
		withdrawalDetails?.data?.data?.payload
	);
	return (
		<AppLayout
			pageTitle='WithDrawal Details'
			loading={withdrawalDetails.isLoading}
		>
			<Card>
				<Descriptions title='User Info' layout='vertical'>
					<Descriptions.Item label='UserName'>
						{withdrawalDetails?.data?.data?.payload?.user?.firstName +
							' ' +
							withdrawalDetails?.data?.data?.payload?.user?.lastName}
					</Descriptions.Item>
					<Descriptions.Item label='Telephone'>
						{withdrawalDetails?.data?.data?.payload?.user?.phone}
					</Descriptions.Item>
					<Descriptions.Item label='Email'>
						{withdrawalDetails?.data?.data?.payload?.user?.email}
					</Descriptions.Item>
					<Descriptions.Item label='Images' span={2}>
						<div className='flex space-x-2'>
							<div className='space-y-2'>
								<label className='block'>Selfie</label>
								<Image
									height={80}
									src={withdrawalDetails?.data?.data?.payload?.user?.selfie}
									sizes='small'
								/>
							</div>

							<div className='space-y-2'>
								<label className='block'>DriveLicense Front</label>
								<Image
									height={80}
									src={
										withdrawalDetails?.data?.data?.payload?.user
											?.driveLicenseFront
									}
									sizes='small'
								/>
							</div>
							<div className='space-y-2'>
								<label className='block'>DriveLicense Back</label>
								<Image
									height={80}
									src={
										withdrawalDetails?.data?.data?.payload?.user
											?.driveLicenseBack
									}
									sizes='small'
								/>
							</div>
							<div className='space-y-2'>
								<label className='block'>Nid Front</label>
								<Image
									height={80}
									src={withdrawalDetails?.data?.data?.payload?.user?.nidFront}
									sizes='small'
								/>
							</div>
							<div className='space-y-2'>
								<label className='block'>nidBack</label>
								<Image
									height={80}
									src={withdrawalDetails?.data?.data?.payload?.user?.nidBack}
									sizes='small'
								/>
							</div>
						</div>
					</Descriptions.Item>
					<Descriptions.Item label='NID'>
						{withdrawalDetails?.data?.data?.payload?.user?.nid}
					</Descriptions.Item>
				</Descriptions>
			</Card>
			<div className='grid grid-cols-5 gap-3 mt-5'>
				<Card className='col-span-3'>
					<Descriptions title='Withdrawal Info' bordered layout='vertical'>
						<Descriptions.Item span={2} label='withdrawalCode'>
							{withdrawalDetails?.data?.data?.payload?.withdrawalCode}
						</Descriptions.Item>
						<Descriptions.Item span={2} label='Account Number'>
							{withdrawalDetails?.data?.data?.payload?.accountNumber}
						</Descriptions.Item>
						<Descriptions.Item span={2} label='Amount'>
							{currencyFormat(withdrawalDetails?.data?.data?.payload?.amount)}
						</Descriptions.Item>
						<Descriptions.Item span={2} label='After Balance'>
							{currencyFormat(
								withdrawalDetails?.data?.data?.payload?.newBalance
							)}
						</Descriptions.Item>
						<Descriptions.Item span={2} label='Before Balance'>
							{currencyFormat(
								withdrawalDetails?.data?.data?.payload?.previousBalance
							)}
						</Descriptions.Item>
						<Descriptions.Item span={2} label='Bank Name'>
							{withdrawalDetails?.data?.data?.payload?.bankName}
						</Descriptions.Item>
						<Descriptions.Item span={2} label='Branch Name'>
							{withdrawalDetails?.data?.data?.payload?.branchName}
						</Descriptions.Item>
						<Descriptions.Item span={2} label='Branch Routing Number'>
							{withdrawalDetails?.data?.data?.payload?.branchRoutingNumber}
						</Descriptions.Item>
						<Descriptions.Item span={2} label='Recipient Address'>
							{withdrawalDetails?.data?.data?.payload?.recipientAddress}
						</Descriptions.Item>
						<Descriptions.Item span={2} label='recipientEmail'>
							{withdrawalDetails?.data?.data?.payload?.recipientEmail}
						</Descriptions.Item>
						<Descriptions.Item span={2} label='recipientFirstName'>
							{withdrawalDetails?.data?.data?.payload?.recipientFirstName}
						</Descriptions.Item>
						<Descriptions.Item span={2} label='recipientLastName'>
							{withdrawalDetails?.data?.data?.payload?.recipientLastName}
						</Descriptions.Item>
						<Descriptions.Item span={2} label='recipientPhone'>
							{withdrawalDetails?.data?.data?.payload?.recipientPhone}
						</Descriptions.Item>
						<Descriptions.Item span={2} label='status'>
							<ShowStatus
								status={withdrawalDetails?.data?.data?.payload?.status}
							>
								{withdrawalDetails?.data?.data?.payload?.status}
							</ShowStatus>
						</Descriptions.Item>
						<Descriptions.Item span={2} label='swiftCode'>
							{withdrawalDetails?.data?.data?.payload?.swiftCode}
						</Descriptions.Item>
					</Descriptions>
				</Card>
				<div className='col-span-2'>
					<Card>
						<UpdateWithDrawalStatus
							data={withdrawalDetails?.data?.data?.payload}
						/>
					</Card>
					<Card title='History' className='mt-5'>
						<List
							itemLayout='horizontal'
							dataSource={withdrawalDetails?.data?.data?.payload?.notes}
							renderItem={(item: any, index) => (
								<List.Item>
									<List.Item.Meta
										title={moment(item?.createdAt).format(
											'MMMM Do YYYY, h:mm:ss a'
										)}
										description={item?.text}
									/>
								</List.Item>
							)}
						/>
					</Card>
				</div>
			</div>
		</AppLayout>
	);
};
export default WithDrawalDetailsPage;
