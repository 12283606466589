import { AppProvider } from './context';
import AppRoutes from './routes';
import { ENV } from './ENV';

const App = () => {
	console.log('App', { ENV, active: process.env.REACT_APP_ENV });
	return (
		<AppProvider>
			<AppRoutes />
		</AppProvider>
	);
};
export default App;
