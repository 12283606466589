import { Button, Form, Input, Select, Spin, message } from 'antd';
import { CarStatus, WithdrawalRequestStatus } from '../../../enums';
import React, { useEffect } from 'react';

import { useUpdateWithdrawalStatus } from '../../../services/quries';

const { Option } = Select;
interface IFProps {
	data: any;
}
const UpdateWithDrawalStatus: React.FC<IFProps> = ({ data }) => {
	const [form] = Form.useForm();
	const updateCarStatusMutation = useUpdateWithdrawalStatus({
		config: {
			onSuccess: (data) => {
				message.success('Status updated successfully');
				window.location.reload();
			},
		},
	});
	const onFinish = (values: any) => {
		updateCarStatusMutation.mutate({
			status: values.status,
			note: values.note,
			withdrawalCode: data.withdrawalCode,
		});
	};

	useEffect(() => {
		form.setFieldsValue({
			status: data?.status,
			note: '',
		});
	}, [data]);

	console.log(data);
	return (
		<>
			<Spin spinning={updateCarStatusMutation.isLoading}>
				<Form
					form={form}
					initialValues={{
						status: data?.status,
						note: '',
					}}
					onFinish={onFinish}
					layout='vertical'
				>
					<Form.Item name='status' label='Status' rules={[{ required: true }]}>
						<Select
							size='large'
							placeholder='Select a option and change input text above'
							allowClear
						>
							<Option value={WithdrawalRequestStatus.PENDING}>
								{WithdrawalRequestStatus.PENDING}
							</Option>
							<Option value={WithdrawalRequestStatus.REJECTED}>
								{WithdrawalRequestStatus.REJECTED}
							</Option>
							<Option value={WithdrawalRequestStatus.COMPLETED}>
								{WithdrawalRequestStatus.COMPLETED}
							</Option>
							<Option value={WithdrawalRequestStatus.PROCESSING}>
								{WithdrawalRequestStatus.PROCESSING}
							</Option>
						</Select>
					</Form.Item>
					<Form.Item name='note' label='Note' rules={[{ required: true }]}>
						<Input.TextArea rows={4} />
					</Form.Item>
					<Form.Item>
						<Button type='primary' htmlType='submit'>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Spin>
		</>
	);
};
export default UpdateWithDrawalStatus;
