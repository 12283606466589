import * as qs from 'qs';

import { Button, Form, Modal, Select, Spin, Switch, message } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { safeObject, toSafeArray } from '../../utils';
import {
	useBodiesQuery,
	useCarsQuery,
	useUsersQuery,
} from '../../services/quries';
import { useMutation, useQuery } from 'react-query';

import { ApiServices } from '../../services/api.service';
import { AppLayout } from '../../themes';
import { CarStatus } from '../../enums';
import { IFilterCarsRequest } from '../../interfaces/request.interfaces';
import { IMAGES } from '../../assets/index';
import { PlusOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import UpdateStatus from './components/UpdateStatus';
import moment from 'moment';
import { queryClient } from '../../config';
import { useDebounceFn } from 'ahooks';
import { useNavigate } from 'react-router';
import { useState } from 'react';

const CarsPage = () => {
	const navigate = useNavigate();
	const createCarMutation = useMutation(ApiServices.createCar, {
		onSuccess: (data) => {
			message.success('Car created successfully');
			form.resetFields();
			queryClient.invalidateQueries(`${ApiServices.filterCar.name}`);
		},
	});
	const carStatusMutation = useMutation(ApiServices.updateCarStatus, {
		onSuccess: (data) => {
			message.success('Status updated successfully');
			queryClient.invalidateQueries(`${ApiServices.filterCar.name}`);
		},
	});
	const carDeleteMutation = useMutation(ApiServices.deleteCar, {
		onSuccess: (data) => {
			message.success('Car deleted successfully');
			queryClient.invalidateQueries(`${ApiServices.filterCar.name}`);
		},
	});
	const [options, setOptions] = useState<IFilterCarsRequest>(
		safeObject({
			page: 1,
			take: 10,
			searchTerm: '',
			isActive: null,
			title: '',
			model: '',
			category: '',
			body: '',
			status: '',
		})
	);
	const userQuery = useQuery({
		queryKey: [`${ApiServices.filterCar.name}`, qs.stringify(options)],
		queryFn: () => ApiServices.filterCar(options),
	});
	const carsQuery = useCarsQuery({
		options: {
			...options,
			body: options?.body ? JSON.stringify([options?.body]) : null,
		},
	});
	const [form] = Form.useForm();
	const { page, take } = options;
	const onUpdateStatus = (item) => {
		Modal.confirm({
			title: 'Are you sure you want to update status?',
			onOk: () =>
				carStatusMutation.mutate({
					id: item?.id,
					data: {
						isActive: !item?.isActive,
					},
				}),
		});
	};
	const onCategoryDelete = (item) => {
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => carDeleteMutation.mutate(item?.id),
		});
	};
	const { run } = useDebounceFn(
		(e) => {
			setOptions({
				...options,
				searchTerm: e.target.value,
			});
		},
		{
			wait: 500,
		}
	);
	const [bodySearchTerm, setBodySearchTerm] = useState('');
	const bodyQuery = useBodiesQuery({
		options: {
			searchTerm: bodySearchTerm,
			isActive: true,
			page: 1,
			take: 10,
		},
	});
	const [userSearchTerm, setUserSearchTerm] = useState('');
	const usersQuery = useUsersQuery({
		options: {
			searchTerm: userSearchTerm,
			page: 1,
			take: 10,
		},
	});
	const columns: ColumnsType<any> = [
		{
			title: 'Cover Image',
			dataIndex: 'coverImage',
			key: 'coverImage',
			render: (src) => {
				return (
					<img
						src={src || IMAGES.NoImage}
						alt=''
						className='w-20 h-20 object-cover rounded-md'
					/>
				);
			},
		},
		{
			title: 'Info',
			dataIndex: 'basicInfo',
			key: 'basicInfo',
			render: (data: any) => {
				return (
					<div className=''>
						<UpdateStatus data={data} />
						<div className='text-sm mt-1 font-semibold'>
							Title: {data?.title}
						</div>
						<div className='text-sm'>Model: {data?.model}</div>
						<div className='text-sm'>Description: {data?.description}</div>
						<div className='text-sm'>
							Facilities: {data?.facilities?.join(', ')}
						</div>
					</div>
				);
			},
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (amount: any) => {
				return (
					<div className='flex flex-col'>
						<div className='text-sm font-semibold'>
							Rent Amount: {amount?.rentAmount}
						</div>
						<div className='text-sm'>Rent CountPer: {amount?.rentCountPer}</div>
						<div className='text-sm'>
							RentCount Per Minimum: {amount?.rentCountPerMinimum}
						</div>
					</div>
				);
			},
		},
		{
			title: 'Belong To',
			dataIndex: 'belongTo',
			key: 'belongTo',
			render: (data: any) => {
				return (
					<div className=''>
						<div className='text-sm mt-1 font-semibold'>
							isFeatured: {data?.isFeatured ? 'Yes' : 'No'}
						</div>
						<div className='text-sm'>Brand: {data?.brand?.title}</div>
						<div className='text-sm'>Category: {data?.category?.title}</div>
						<div className='text-sm'>Body: {data?.body?.title}</div>
						<div className='text-sm'>
							Owner: {data?.owner?.firstName + data?.owner?.lastName}
						</div>
					</div>
				);
			},
		},
		{
			title: 'Is Active',
			key: 'isActive',
			dataIndex: 'isActive',
			render: (item: any) => {
				return (
					<Switch
						checked={item?.isActive}
						onChange={() => {
							onUpdateStatus(item);
						}}
					/>
				);
			},
		},
		{
			title: 'Created At',
			key: 'createdAt',
			dataIndex: 'createdAt',
		},
		{
			title: 'Action',
			key: 'action',
			align: 'right',
			render: (item) => {
				return (
					<>
						<div className='flex justify-end'>
							<Button
								type='primary'
								className='mr-2 cursor-pointer'
								size='small'
								onClick={() => {
									navigate(`/cars/update/${item?.id}`);
								}}
							>
								Update
							</Button>
							<Button
								type='primary'
								className='mr-2'
								size='small'
								danger
								onClick={() => onCategoryDelete(item)}
							>
								Delete
							</Button>
						</div>
					</>
				);
			},
		},
	];
	const data: any[] = toSafeArray(carsQuery?.data?.data?.payload)?.map(
		(item: any) => ({
			key: item?.id,
			id: item?.id,
			basicInfo: item,
			amount: item,
			belongTo: item,
			coverImage: `${item?.coverImage?.link}` || 'N/A',
			isActive: item,
			createdAt: moment(item?.createdAt).format('DD/MM/YYYY') || 'N/A',
			action: item,
		})
	);
	return (
		<AppLayout
			pageTitle='Cars Management'
			loading={
				userQuery?.isLoading || carsQuery.isLoading || usersQuery.isLoading
			}
		>
			<Form
				layout='vertical'
				className='flex space-x-4 mb-2 bg-white p-3 rounded-md items-center'
			>
				<Form.Item label='Search'>
					<Search
						placeholder='Search ...'
						onChange={run}
						style={{ width: 200 }}
					/>
				</Form.Item>
				<Form.Item label='Active'>
					<Select
						defaultValue={'ALL'}
						style={{ width: 120 }}
						onChange={(e: any) => {
							setOptions({
								...options,
								isActive: e === '' ? null : e === 'Active' ? true : false,
							});
						}}
						options={[
							{ value: 'Active', label: 'ACTIVE' },
							{ value: 'InActive', label: 'IN ACTIVE' },
							{ value: '', label: 'ALL' },
						]}
					/>
				</Form.Item>
				<Form.Item label='Status'>
					<Select
						defaultValue={'ALL'}
						style={{ width: 120 }}
						onChange={(e: any) => {
							setOptions({
								...options,
								status: e,
							});
						}}
						options={[
							{ value: CarStatus.PENDING, label: CarStatus.PENDING },
							{ value: CarStatus.DRAFT, label: CarStatus.DRAFT },
							{ value: CarStatus.REJECTED, label: CarStatus.REJECTED },
							{ value: CarStatus.APPROVED, label: CarStatus.APPROVED },
							{ value: '', label: 'ALL' },
						]}
					/>
				</Form.Item>
				<Form.Item
					className='w-32'
					name='body'
					label='Select Body'
					rules={[{ required: true }]}
				>
					<Select
						labelInValue
						size='large'
						searchValue={bodySearchTerm}
						showSearch
						filterOption={false}
						onSearch={(e) => {
							setBodySearchTerm(e);
						}}
						notFoundContent={bodyQuery.isLoading ? <Spin size='small' /> : null}
						options={bodyQuery.data?.data?.payload?.map((item) => ({
							label: item.title,
							value: item.id,
						}))}
						onChange={(e) => {
							setOptions({
								...options,
								body: e?.value,
							});
						}}
					/>
				</Form.Item>
				<Form.Item
					className='w-32'
					name='owner'
					label='Owner'
					rules={[{ required: true }]}
				>
					<Select
						labelInValue
						size='large'
						searchValue={userSearchTerm}
						showSearch
						filterOption={false}
						onSearch={(e) => {
							setUserSearchTerm(e);
						}}
						onChange={(e) => {
							setOptions({
								...options,
								owner: e?.value,
							});
						}}
						notFoundContent={
							usersQuery.isLoading ? <Spin size='small' /> : null
						}
						options={usersQuery.data?.data?.payload?.map((item) => ({
							label: item?.firstName + ' ' + item?.lastName,
							value: item.id,
						}))}
					/>
				</Form.Item>
				<div className='flex-1'>
					<Button
						type='primary'
						className='float-right flex items-center'
						onClick={() => navigate('/cars/create')}
					>
						<PlusOutlined />
						Create Car
					</Button>
				</div>
			</Form>
			<Table
				loading={createCarMutation.isLoading}
				columns={columns}
				dataSource={data}
				pagination={{
					current: page,
					pageSize: take,
					total: userQuery?.data?.data?.total,
					onChange(page, pageSize) {
						setOptions({
							...options,
							page,
							take: pageSize,
						});
					},
				}}
			/>
		</AppLayout>
	);
};
export default CarsPage;
