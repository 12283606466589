import CreateUserPage from './CreateUserPage';
import UpdateUserPage from './UpdateUserPage';
import UsersPage from './UsersPage';
export const UsersRoutes = [
	{ path: '', element: <UsersPage /> },
	{ path: 'create', element: <CreateUserPage /> },
	{
		path: 'update/:id',
		element: <UpdateUserPage />,
	},
];
