import { Button, Form, Input, Select, Spin, message } from 'antd';
import React, { useState } from 'react';

import { CarStatus } from '../../../enums';
import { DownOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import ShowStatus from '../../../utils/util-jsx';
import { useUpdateCarStatusMutation } from '../../../services/quries';

const { Option } = Select;
interface IFProps {
	data: any;
}
const UpdateStatus: React.FC<IFProps> = ({ data }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const updateCarStatusMutation = useUpdateCarStatusMutation({
		config: {
			onSuccess: (data) => {
				message.success('Status updated successfully');
				setIsModalOpen(false);
			},
		},
	});
	const onFinish = (values: any) => {
		updateCarStatusMutation.mutate({
			data: {
				status: values.status,
				note: values.note,
			},
			id: data.id,
		});
	};
	return (
		<>
			<ShowStatus
				status={data?.status}
				onClick={() => {
					setIsModalOpen(true);
				}}>
				{data?.status}
				<DownOutlined />
			</ShowStatus>
			<Modal
				footer={null}
				title='Update Status'
				open={isModalOpen}
				onOk={() => {
					setIsModalOpen(false);
				}}
				onCancel={() => {
					setIsModalOpen(false);
				}}>
				<Spin spinning={updateCarStatusMutation.isLoading}>
					<Form
						initialValues={{
							status: data?.status,
							note: '',
						}}
						onFinish={onFinish}
						layout='vertical'>
						<Form.Item
							name='status'
							label='Status'
							rules={[{ required: true }]}>
							<Select
								size='large'
								placeholder='Select a option and change input text above'
								allowClear>
								<Option value={CarStatus.PENDING}>{CarStatus.PENDING}</Option>
								<Option value={CarStatus.DRAFT}>{CarStatus.DRAFT}</Option>
								<Option value={CarStatus.APPROVED}>{CarStatus.APPROVED}</Option>
								<Option value={CarStatus.REJECTED}>{CarStatus.REJECTED}</Option>
							</Select>
						</Form.Item>
						<Form.Item
							name='note'
							label='Note'
							rules={[{ required: true }]}>
							<Input.TextArea rows={4} />
						</Form.Item>
						<Form.Item>
							<Button
								type='primary'
								htmlType='submit'>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};
export default UpdateStatus;
