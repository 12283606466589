import * as qs from 'qs';

import { Button, Form, Modal, Select, Switch, message } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { safeObject, toSafeArray } from '../../utils';
import { useMutation, useQuery } from 'react-query';

import { ApiServices } from '../../services/api.service';
import { AppLayout } from '../../themes';
import BrandForm from './components/BrandForm';
import { IFilterCategorisRequest } from '../../interfaces/request.interfaces';
import { IMAGES } from '../../assets/index';
import { PlusOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import moment from 'moment';
import { queryClient } from '../../config';
import { useDebounceFn } from 'ahooks';
import { useState } from 'react';

const BrandsPage = () => {
	const createBrandMutation = useMutation(ApiServices.CreateBrand, {
		onSuccess: (data) => {
			message.success('Brand created successfully');
			queryClient.invalidateQueries(`${ApiServices.filterBrand.name}`);
			setIsOpenCreateModal(false);
		},
	});
	const brandStatusMutation = useMutation(ApiServices.updateBrandStatus, {
		onSuccess: (data) => {
			message.success('Status updated successfully');
			queryClient.invalidateQueries(`${ApiServices.filterBrand.name}`);
		},
	});
	const [options, setOptions] = useState<IFilterCategorisRequest>(
		safeObject({
			page: 1,
			take: 10,
			searchTerm: '',
			isActive: null,
			title: '',
		})
	);
	const brandQuery = useQuery({
		queryKey: [`${ApiServices.filterBrand.name}`, qs.stringify(options)],
		queryFn: () => ApiServices.filterBrand(options),
	});
	const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
	const { page, take } = options;
	const onUpdateStatus = (item) => {
		Modal.confirm({
			title: 'Are you sure you want to update status?',
			onOk: () =>
				brandStatusMutation.mutate({
					id: item?.id,
					data: {
						isActive: !item?.isActive,
					},
				}),
		});
	};
	const { run } = useDebounceFn(
		(e) => {
			setOptions({
				...options,
				searchTerm: e.target.value,
			});
		},
		{
			wait: 500,
		}
	);
	const columns: ColumnsType<any> = [
		{
			title: 'Image',
			dataIndex: 'image',
			key: 'image',
			render: (src) => {
				return (
					<img
						src={src || IMAGES.NoImage}
						alt=''
						className='w-20 h-20 object-cover rounded-sm'
					/>
				);
			},
		},
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'Is Active',
			key: 'isActive',
			render: (item: any) => {
				return (
					<Switch
						checked={item?.isActive}
						onChange={() => {
							onUpdateStatus(item);
						}}
					/>
				);
			},
		},
		{
			title: 'Created At',
			key: 'createdAt',
			dataIndex: 'createdAt',
		},
		{
			title: 'Action',
			key: 'action',
			align: 'right',
			render: (record) => <BrandsRowAction item={record} />,
		},
	];
	const data: any[] = toSafeArray(brandQuery?.data?.data?.payload)?.map(
		(item: any) => ({
			key: item?.id,
			id: item?.id,
			image: item?.image || 'N/A',
			title: item?.title || 'N/A',
			description: item?.description || 'N/A',
			isActive: item?.isActive,
			createdAt: moment(item?.createdAt).format('DD/MM/YYYY') || 'N/A',
			action: item,
		})
	);
	return (
		<AppLayout
			pageTitle='Brands'
			loading={brandQuery?.isLoading || brandStatusMutation.isLoading}
		>
			<Form
				layout='vertical'
				className='flex space-x-4 mb-2 bg-white p-3 rounded-md items-center'
			>
				<Form.Item label='Search'>
					<Search
						placeholder='Search ...'
						onChange={run}
						style={{ width: 200 }}
					/>
				</Form.Item>
				<Form.Item label='Status'>
					<Select
						defaultValue={'ALL'}
						style={{ width: 120 }}
						onChange={(e: any) => {
							setOptions({
								...options,
								isActive: e === '' ? null : e === 'Active' ? true : false,
							});
						}}
						options={[
							{ value: 'Active', label: 'ACTIVE' },
							{ value: 'InActive', label: 'IN ACTIVE' },
							{ value: '', label: 'ALL' },
						]}
					/>
				</Form.Item>
				<div className='flex-1'>
					<Button
						type='primary'
						className='float-right flex items-center'
						onClick={() => setIsOpenCreateModal(true)}
					>
						<PlusOutlined />
						Create Brand
					</Button>
				</div>
			</Form>
			<Table
				columns={columns}
				dataSource={data}
				pagination={{
					current: page,
					pageSize: take,
					total: brandQuery?.data?.data?.total,
					onChange(page, pageSize) {
						setOptions({
							...options,
							page,
							take: pageSize,
						});
					},
				}}
			/>
			<Modal
				title='Create Brand'
				centered
				open={isOpenCreateModal}
				onCancel={() => setIsOpenCreateModal(false)}
				footer={null}
			>
				<BrandForm
					isLoading={createBrandMutation.isLoading}
					onSubmit={(v) => {
						createBrandMutation.mutate(v);
					}}
				/>
			</Modal>
		</AppLayout>
	);
};
export default BrandsPage;
const BrandsRowAction = ({ item }) => {
	const brandDeleteMutation = useMutation(ApiServices.deleteBrand, {
		onSuccess: (data) => {
			message.success('Brand deleted successfully');
			queryClient.invalidateQueries(`${ApiServices.filterBrand.name}`);
		},
	});
	const brandUpdateMutation = useMutation(ApiServices.updateBrand, {
		onSuccess: (data) => {
			message.success('Brand updated successfully');
			queryClient.invalidateQueries(`${ApiServices.filterBrand.name}`);
			setUpdateModalVisiable(false);
		},
	});
	const [updateModalVisiable, setUpdateModalVisiable] = useState(false);
	const onBrandDelete = () => {
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => brandDeleteMutation.mutate(item?.id),
		});
	};
	return (
		<>
			<div className='flex justify-end'>
				<Button
					type='primary'
					className='mr-2 cursor-pointer'
					size='small'
					onClick={() => {
						setUpdateModalVisiable(true);
					}}
				>
					Update
				</Button>
				<Button
					type='primary'
					className='mr-2'
					size='small'
					danger
					onClick={() => onBrandDelete()}
				>
					Delete
				</Button>
			</div>
			<Modal
				title='Update Brand'
				centered
				open={updateModalVisiable}
				onCancel={() => setUpdateModalVisiable(false)}
				footer={null}
			>
				<BrandForm
					isLoading={brandUpdateMutation.isLoading}
					initialValues={{
						title: item?.title,
						image: item?.image,
						isActive: item?.isActive,
					}}
					onSubmit={(v) => {
						brandUpdateMutation.mutate({
							id: item?.id,
							data: v,
						});
					}}
				/>
			</Modal>
		</>
	);
};
