import * as qs from 'qs';

import {
	IBaseFilterRequest,
	IChangeCarStatusRequest,
	ICreateBodyRequest,
	ICreateBrand,
	ICreateCategory,
	ICreateUser,
	IFilterBrandsRequest,
	IFilterCarsRequest,
	IFilterCategorisRequest,
	IFilterReservationsRequest,
	IFilterUsersRequest,
	ISignInRequest,
	ISignUpRequest,
	IStatusUpdateRequest,
	IUpdate,
	IUpdateBodyRequest,
	IUpdateBrand,
	IUpdateCategory,
	IUpdateUser,
	IWithDrawalStatusUpadate,
} from '../interfaces/request.interfaces';
import {
	ICreateCarRequest,
	ICreateImageRequest,
} from './../interfaces/request.interfaces';

import { CoreAxiosInstance } from '../config/index';
import { safeObject } from '../utils';

export const ApiServices = {
	signIn: async (data: ISignInRequest) => {
		return await CoreAxiosInstance.post('/auth/login/admin', data);
	},
	signUp: async (data: ISignUpRequest) => {
		return await CoreAxiosInstance.post('/auth/register/admin', data);
	},
	userById: async (id: string) => {
		return await CoreAxiosInstance.get(`/users/${id}`);
	},
	filterUsers: async (options: IFilterUsersRequest) => {
		options = safeObject(options);
		return await CoreAxiosInstance.get(`/users?${qs.stringify(options)}`);
	},
	updateUserStatus: async (payload: IUpdate<IStatusUpdateRequest>) => {
		const { id, data } = payload;
		return await CoreAxiosInstance.put(`/users/status/${id}`, data);
	},
	createUser: async (data: ICreateUser) => {
		return await CoreAxiosInstance.post(`/users`, data);
	},
	deleteUser: async (id: string) => {
		return await CoreAxiosInstance.delete(`/users/${id}`);
	},
	updateUser: async (payload: IUpdate<IUpdateUser>) => {
		const { id, data } = payload;
		return await CoreAxiosInstance.put(`/users/${id}`, data);
	},
	createCcategory: async (data: ICreateCategory) => {
		return await CoreAxiosInstance.post(`/categories`, data);
	},
	filterCategories: async (options: IFilterCategorisRequest) => {
		options = safeObject(options);
		return await CoreAxiosInstance.get(`/categories?${qs.stringify(options)}`);
	},
	categoryById: async (id: string) => {
		return await CoreAxiosInstance.get(`/categories/${id}`);
	},
	updateCategoryStatus: async (payload: IUpdate<IStatusUpdateRequest>) => {
		const { id, data } = payload;
		return await CoreAxiosInstance.put(`/categories/status/${id}`, data);
	},
	updateCategory: async (payload: IUpdate<IUpdateCategory>) => {
		const { id, data } = payload;
		return await CoreAxiosInstance.put(`/categories/${id}`, data);
	},
	deleteCategory: async (id: string) => {
		return await CoreAxiosInstance.delete(`/categories/${id}`);
	},
	CreateBrand: async (data: ICreateBrand) => {
		return await CoreAxiosInstance.post(`/brands`, data);
	},
	filterBrand: async (options: IFilterBrandsRequest) => {
		options = safeObject(options);
		return await CoreAxiosInstance.get(`/brands?${qs.stringify(options)}`);
	},
	updateBrandStatus: async (payload: IUpdate<IStatusUpdateRequest>) => {
		const { id, data } = payload;
		return await CoreAxiosInstance.put(`/brands/status/${id}`, data);
	},
	deleteBrand: async (id: string) => {
		return await CoreAxiosInstance.delete(`/brands/${id}`);
	},
	updateBrand: async (payload: IUpdate<IUpdateBrand>) => {
		const { id, data } = payload;
		return await CoreAxiosInstance.put(`/brands/${id}`, data);
	},
	createCar: async (data: ICreateCarRequest) => {
		return await CoreAxiosInstance.post(`/cars`, data);
	},
	filterCar: async (options: IFilterCarsRequest) => {
		options = safeObject(options);
		return await CoreAxiosInstance.get(`/cars?${qs.stringify(options)}`);
	},
	updateCarStatus: async (payload: IUpdate<IStatusUpdateRequest>) => {
		const { id, data } = payload;
		return await CoreAxiosInstance.put(`/cars/status/${id}`, data);
	},
	deleteCar: async (id: string) => {
		return await CoreAxiosInstance.delete(`/cars/${id}`);
	},
	updateCar: async (payload: IUpdate<ICreateCarRequest>) => {
		const { id, data } = payload;
		return await CoreAxiosInstance.put(`/cars/${id}`, data);
	},
	carById: async (id: string) => {
		return await CoreAxiosInstance.get(`/cars/${id}`);
	},
	createBody: async (data: ICreateBodyRequest) => {
		return await CoreAxiosInstance.post(`/body`, data);
	},
	filterBody: async (options: IBaseFilterRequest) => {
		options = safeObject(options);
		return await CoreAxiosInstance.get(`/body?${qs.stringify(options)}`);
	},
	deleteBody: async (id: string) => {
		return await CoreAxiosInstance.delete(`/body/${id}`);
	},
	bodyStatusUpdate: async (payload: IUpdate<IStatusUpdateRequest>) => {
		const { id, data } = payload;
		return await CoreAxiosInstance.put(`/body/${id}`, data);
	},
	findBodyById: async (id: string) => {
		return await CoreAxiosInstance.get(`/body/${id}`);
	},
	updateBody: async (payload: IUpdate<IUpdateBodyRequest>) => {
		const { id, data } = payload;
		return await CoreAxiosInstance.put(`/body/${id}`, data);
	},
	createImages: async (data: ICreateImageRequest) => {
		const fm = new FormData();
		fm.append('file', data.file);
		fm.append('type', data.type);
		return await CoreAxiosInstance.post(`/images`, data);
	},
	changeCarStatus: async (payload: IUpdate<IChangeCarStatusRequest>) => {
		return CoreAxiosInstance.put(
			`/cars/update-status/${payload.id}`,
			payload.data
		);
	},
	reservations: async (options: IFilterReservationsRequest) => {
		options = safeObject(options);
		return await CoreAxiosInstance.get(
			`/reservations?${qs.stringify(options)}`
		);
	},
	filterWithdrawal: async (options: IFilterReservationsRequest) => {
		options = safeObject(options);
		return await CoreAxiosInstance.get(`/withdrawal?${qs.stringify(options)}`);
	},
	withdrawalDetails: async (id: string) => {
		return await CoreAxiosInstance.get(`/withdrawal/${id}`);
	},
	updateWithdrawalStatus: async (payload: IWithDrawalStatusUpadate) => {
		const { note, status, withdrawalCode } = payload;
		return await CoreAxiosInstance.put(
			`/withdrawal/update-status/${withdrawalCode}`,
			{
				note,
				status,
			}
		);
	},
};
