import * as qs from 'qs';

import { Form, Select, Spin, message } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import {
	currencyFormat,
	getDuration,
	safeObject,
	toSafeArray,
} from '../../utils';
import { useMutation, useQuery } from 'react-query';
import { useReservationsQuery, useUsersQuery } from '../../services/quries';

import { ApiServices } from '../../services/api.service';
import { AppLayout } from '../../themes';
import { CarStatus } from '../../enums';
import { IFilterCarsRequest } from '../../interfaces/request.interfaces';
import { IMAGES } from '../../assets/index';
import Search from 'antd/es/input/Search';
import moment from 'moment';
import { queryClient } from '../../config';
import { useDebounceFn } from 'ahooks';
import { useState } from 'react';

const ReservationsPage = () => {
	const createCarMutation = useMutation(ApiServices.createCar, {
		onSuccess: (data) => {
			message.success('Car created successfully');
			form.resetFields();
			queryClient.invalidateQueries(`${ApiServices.filterCar.name}`);
		},
	});
	const [options, setOptions] = useState<IFilterCarsRequest>(
		safeObject({
			page: 1,
			take: 10,
			searchTerm: '',
			isActive: null,
			title: '',
			status: '',
		})
	);
	const userQuery = useQuery({
		queryKey: [`${ApiServices.filterCar.name}`, qs.stringify(options)],
		queryFn: () => ApiServices.filterCar(options),
	});
	const reservationQuery = useReservationsQuery({
		options: {
			...options,
		},
	});
	const [form] = Form.useForm();
	const { page, take } = options;
	const { run } = useDebounceFn(
		(e) => {
			setOptions({
				...options,
				searchTerm: e.target.value,
			});
		},
		{
			wait: 500,
		}
	);
	const [userSearchTerm, setUserSearchTerm] = useState('');
	const usersQuery = useUsersQuery({
		options: {
			searchTerm: userSearchTerm,
			page: 1,
			take: 10,
		},
	});
	const columns: ColumnsType<any> = [
		// {
		// 	title: 'Cover Image',
		// 	dataIndex: 'coverImage',
		// 	key: 'coverImage',
		// 	render: (src) => {
		// 		return (
		// 			<img
		// 				src={  src || IMAGES.NoImage}
		// 				alt='image'
		// 				className='w-20 h-20 object-cover rounded-md'
		// 			/>
		// 		);
		// 	},
		// },
		{
			title: 'Info',
			dataIndex: 'basicInfo',
			key: 'basicInfo',
			render: (data: any) => {
				return (
					<div className='space-y-1'>
						<div className='text-sm mt-1 font-semibold'>
							Code: {data?.reservationCode}
						</div>
						<div className='text-sm'>Status: {data?.reservationStatus}</div>
						<div className='text-sm'>PaymentStatus: {data?.paymentStatus}</div>
					</div>
				);
			},
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (amount: any) => {
				return (
					<div className='flex flex-col'>
						<div className='text-sm font-semibold'>
							Hourly Rate: {currencyFormat(amount?.hourlyRate)}
						</div>
						<div className='text-sm'>
							SubTotal: {currencyFormat(amount?.subTotal)}
						</div>
						<div className='text-sm'>
							Total: {currencyFormat(amount?.total)}
						</div>
					</div>
				);
			},
		},
		{
			title: 'Booking Info',
			dataIndex: 'bookingInfo',
			key: 'bookingInfo',
			render: (data: any) => {
				return (
					<div className=''>
						<div className='text-sm mt-1 font-semibold'>
							Duration:{' '}
							{getDuration(Number(data?.pickupDate), Number(data?.dropOffDate))}
						</div>
						<div className='text-sm'>DropOff: {data?.dropOffLocation}</div>
						<div className='text-sm'>Pickup: {data?.pickupLocation}</div>
					</div>
				);
			},
		},
		{
			title: 'Car Info',
			dataIndex: 'carInfo',
			key: 'carInfo',
			render: (data: any) => {
				return (
					<div className=''>
						<img
							src={data?.car?.coverImage?.link || IMAGES.NoImage}
							alt=''
							className='w-10 h-10 object-cover rounded-md'
						/>
						<div className='text-sm mt-1 font-semibold'>
							Title: {data?.car?.title}
						</div>
						<div className='text-sm'>
							RentAmount: {data?.car?.rentAmount} / {data?.car?.rentCountPer}
						</div>
					</div>
				);
			},
		},
		{
			title: 'Driver Info',
			dataIndex: 'driverInfo',
			key: 'driverInfo',
			render: (data: any) => {
				return (
					<div className=''>
						<div className='text-sm mt-1 font-semibold'>
							Name: {data?.driver?.firstName + ' ' + data?.driver?.lastName}
						</div>
						<div className='text-sm'>Phone: {data?.driver?.phone}</div>
						<div className='text-sm'>Email: {data?.driver?.email}</div>
					</div>
				);
			},
		},
		{
			title: 'Owner Info',
			dataIndex: 'ownerInfo',
			key: 'ownerInfo',
			render: (data: any) => {
				return (
					<div className=''>
						<div className='text-sm mt-1 font-semibold'>
							Name:{' '}
							{(data?.owner?.firstName || '') +
								' ' +
								(data?.owner?.lastName || '')}
						</div>
						<div className='text-sm'>Phone: {data?.owner?.phone}</div>
						<div className='text-sm'>Email: {data?.owner?.email}</div>
					</div>
				);
			},
		},
		{
			title: 'Created At',
			key: 'createdAt',
			dataIndex: 'createdAt',
		},
	];
	const data: any[] = toSafeArray(reservationQuery?.data?.data?.payload)?.map(
		(item: any) => {
			console.log(item);
			return {
				key: item?.id,
				id: item?.id,
				basicInfo: item,
				amount: item,
				bookingInfo: item,
				driverInfo: item,
				ownerInfo: item,
				carInfo: item,
				driver: item,
				coverImage: `${item?.car?.coverImage?.link}` || 'N/A',
				isActive: item,
				createdAt: moment(item?.createdAt).format('DD/MM/YYYY') || 'N/A',
			};
		}
	);
	return (
		<AppLayout
			pageTitle='Reservations Management'
			loading={
				userQuery?.isLoading ||
				reservationQuery.isLoading ||
				usersQuery.isLoading
			}
		>
			<Form
				layout='vertical'
				className='flex space-x-4 mb-2 bg-white p-3 rounded-md items-center'
			>
				<Form.Item label='Search'>
					<Search
						placeholder='Search ...'
						onChange={run}
						style={{ width: 200 }}
					/>
				</Form.Item>
				<Form.Item label='Active'>
					<Select
						defaultValue={'ALL'}
						style={{ width: 120 }}
						onChange={(e: any) => {
							setOptions({
								...options,
								isActive: e === '' ? null : e === 'Active' ? true : false,
							});
						}}
						options={[
							{ value: 'Active', label: 'ACTIVE' },
							{ value: 'InActive', label: 'IN ACTIVE' },
							{ value: '', label: 'ALL' },
						]}
					/>
				</Form.Item>
				<Form.Item label='Status'>
					<Select
						defaultValue={'ALL'}
						style={{ width: 120 }}
						onChange={(e: any) => {
							setOptions({
								...options,
								status: e,
							});
						}}
						options={[
							{ value: CarStatus.PENDING, label: CarStatus.PENDING },
							{ value: CarStatus.DRAFT, label: CarStatus.DRAFT },
							{ value: CarStatus.REJECTED, label: CarStatus.REJECTED },
							{ value: CarStatus.APPROVED, label: CarStatus.APPROVED },
							{ value: '', label: 'ALL' },
						]}
					/>
				</Form.Item>
				<Form.Item
					className='w-32'
					name='owner'
					label='Owner'
					rules={[{ required: true }]}
				>
					<Select
						labelInValue
						size='large'
						searchValue={userSearchTerm}
						showSearch
						filterOption={false}
						onSearch={(e) => {
							setUserSearchTerm(e);
						}}
						onChange={(e) => {
							setOptions({
								...options,
								owner: e?.value,
							});
						}}
						notFoundContent={
							usersQuery.isLoading ? <Spin size='small' /> : null
						}
						options={usersQuery.data?.data?.payload?.map((item) => ({
							label: item?.firstName + ' ' + item?.lastName,
							value: item.id,
						}))}
					/>
				</Form.Item>
			</Form>
			<Table
				loading={createCarMutation.isLoading}
				columns={columns}
				dataSource={data}
				bordered
				pagination={{
					current: page,
					pageSize: take,
					total: userQuery?.data?.data?.total,
					onChange(page, pageSize) {
						setOptions({
							...options,
							page,
							take: pageSize,
						});
					},
				}}
			/>
		</AppLayout>
	);
};
export default ReservationsPage;
