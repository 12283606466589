import { Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

const UnderConstructions = () => {
	return (
		<div className='flex h-full w-full items-center justify-center'>
			<Result icon={<SmileOutlined />} title='Under Construction!' />
		</div>
	);
};

export default UnderConstructions;
