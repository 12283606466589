import {
	IBaseFilterRequest,
	IFilterCarsRequest,
} from '../interfaces/request.interfaces';
import { MutationConfig, QueryConfig, queryClient } from '../config';
import { useMutation, useQuery } from 'react-query';

import { ApiServices } from './api.service';
import { stringify } from 'qs';

type UseCreateBodyMutation = {
	config?: MutationConfig<typeof ApiServices.createBody>;
};
export const useCreateBodyMutation = ({ config }: UseCreateBodyMutation) => {
	return useMutation({
		...config,
		mutationFn: ApiServices.createBody,
		onSettled: () => {
			queryClient.invalidateQueries(ApiServices.filterBody.name);
		},
	});
};
type UseDeleteBodyMutation = {
	config?: MutationConfig<typeof ApiServices.deleteBody>;
};
export const useDeleteBodyMutation = ({ config }: UseDeleteBodyMutation) => {
	return useMutation({
		...config,
		mutationFn: ApiServices.deleteBody,
		onSettled: () => {
			queryClient.invalidateQueries(ApiServices.filterBody.name);
		},
	});
};
type UseBodyStatusMutation = {
	config?: MutationConfig<typeof ApiServices.bodyStatusUpdate>;
};
export const useBodyStatusMutation = ({ config }: UseBodyStatusMutation) => {
	return useMutation({
		...config,
		mutationFn: ApiServices.bodyStatusUpdate,
		onSettled: () => {
			queryClient.invalidateQueries(ApiServices.filterBody.name);
		},
	});
};
type UseBodyUpdateMutation = {
	config?: MutationConfig<typeof ApiServices.updateBody>;
};
export const useBodyUpdateMutation = ({ config }: UseBodyUpdateMutation) => {
	return useMutation({
		...config,
		mutationFn: ApiServices.updateBody,
		onSettled: () => {
			queryClient.invalidateQueries(ApiServices.filterBody.name);
		},
	});
};
type UseCreateImagesMutation = {
	config?: MutationConfig<typeof ApiServices.createImages>;
};
export const useCreateImagesMutation = ({
	config,
}: UseCreateImagesMutation) => {
	return useMutation({
		...config,
		mutationFn: ApiServices.createImages,
	});
};
type UseBodiesQuery = {
	config?: QueryConfig<typeof ApiServices.filterBody>;
	options: IBaseFilterRequest;
};
export const useBodiesQuery = ({ config, options }: UseBodiesQuery) => {
	return useQuery({
		...config,
		queryFn: () => ApiServices.filterBody(options),
		queryKey: [ApiServices.filterBody.name, stringify(options)],
	});
};
type UseFindBodiesByIdQuery = {
	config?: QueryConfig<typeof ApiServices.filterBody>;
	id: string;
};
export const useFindBodiesByIdQuery = ({
	config,
	id,
}: UseFindBodiesByIdQuery) => {
	return useQuery({
		...config,
		queryFn: () => ApiServices.findBodyById(id),
		queryKey: [ApiServices.findBodyById.name, id],
	});
};
type UseCarsQuery = {
	config?: QueryConfig<typeof ApiServices.filterCar>;
	options: IFilterCarsRequest;
};
export const useCarsQuery = ({ config, options }: UseCarsQuery) => {
	return useQuery({
		...config,
		queryFn: () => ApiServices.filterCar(options),
		queryKey: [ApiServices.filterCar.name, stringify(options)],
	});
};
type UseUsersQuery = {
	config?: QueryConfig<typeof ApiServices.filterUsers>;
	options: IBaseFilterRequest;
};
export const useUsersQuery = ({ config, options }: UseUsersQuery) => {
	return useQuery({
		...config,
		queryFn: () => ApiServices.filterUsers(options),
		queryKey: [ApiServices.filterUsers.name, stringify(options)],
	});
};
type UseCarsFindByIdQuery = {
	config?: QueryConfig<typeof ApiServices.carById>;
	id: string;
};
export const useCarsFindByIdQuery = ({ config, id }: UseCarsFindByIdQuery) => {
	return useQuery({
		...config,
		queryFn: () => ApiServices.carById(id),
		queryKey: [ApiServices.carById.name],
	});
};
type UseUpdateCarMutation = {
	config?: MutationConfig<typeof ApiServices.updateCar>;
};
export const useUpdateCarMutation = ({ config }: UseUpdateCarMutation) => {
	return useMutation({
		...config,
		mutationFn: ApiServices.updateCar,
	});
};
type UseUpdateCarStatusMutation = {
	config?: MutationConfig<typeof ApiServices.changeCarStatus>;
};
export const useUpdateCarStatusMutation = ({
	config,
}: UseUpdateCarStatusMutation) => {
	return useMutation({
		...config,
		mutationFn: ApiServices.changeCarStatus,
		onSettled: () => {
			queryClient.invalidateQueries(`${ApiServices.filterCar.name}`);
		},
	});
};
type UseReservationsQuery = {
	config?: QueryConfig<typeof ApiServices.carById>;
	options: IBaseFilterRequest;
};
export const useReservationsQuery = ({
	config,
	options,
}: UseReservationsQuery) => {
	return useQuery({
		...config,
		queryFn: () => ApiServices.reservations(options),
		queryKey: [ApiServices.reservations.name],
	});
};

type UseFilterWithdrawal = {
	config?: QueryConfig<typeof ApiServices.filterWithdrawal>;
	options: IBaseFilterRequest;
};
export const useFilterWithdrawal = ({
	config,
	options,
}: UseFilterWithdrawal) => {
	return useQuery({
		...config,
		queryFn: () => ApiServices.filterWithdrawal(options),
		queryKey: [ApiServices.filterWithdrawal.name],
	});
};

type UseWithdrawalDetails = {
	config?: QueryConfig<typeof ApiServices.withdrawalDetails>;
	id: string;
};
export const useWithdrawalDetails = ({ config, id }: UseWithdrawalDetails) => {
	return useQuery({
		...config,
		queryFn: () => ApiServices.withdrawalDetails(id),
		queryKey: [ApiServices.withdrawalDetails.name],
	});
};

type UseUpdateWithdrawalStatus = {
	config?: MutationConfig<typeof ApiServices.updateWithdrawalStatus>;
};
export const useUpdateWithdrawalStatus = ({
	config,
}: UseUpdateWithdrawalStatus) => {
	return useMutation({
		...config,
		mutationFn: ApiServices.updateWithdrawalStatus,
		onSettled: () => {
			queryClient.invalidateQueries(`${ApiServices.filterWithdrawal.name}`);
		},
	});
};
