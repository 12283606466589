import jwt_decode from 'jwt-decode';
export const concatFilterQuery = (options: any): string => {
	return Object.keys(options)
		.map((x) => {
			return `${x}=${options[x]}`;
		})
		.join('&');
};
const storagePrefix = 'jd_react_';
export const storage = {
	getToken: (): string | false => {
		let item: any = localStorage.getItem(`${storagePrefix}token`);
		return JSON.parse(item) as string;
	},
	getDecodedToken: (): any => {
		let item: any = localStorage.getItem(`${storagePrefix}token`);
		return jwt_decode(item);
	},
	setToken: (token: string) => {
		localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
	},
	clear: () => {
		localStorage.clear();
	},
	setData(data: any, key: string) {
		localStorage.setItem(key, JSON.stringify(data));
	},
	getDate(key: string) {
		let item = localStorage.getItem(key);
		if (!item) {
			return;
		}
		return JSON.parse(item);
	},
	removeData(key: string) {
		localStorage.removeItem(key);
	},
};
export const toSafeArray = (data: any) => {
	if (
		!data &&
		!Array.isArray(data) &&
		data?.length === 0 &&
		data?.length === undefined &&
		data?.length === null &&
		data?.length === ''
	) {
		return [];
	}
	return data;
};
export const removeIFrameInRootHtml = () => {
	const iframe = document.getElementById('iframe');
	if (iframe) {
		iframe.remove();
	}
};
export const getBase64 = (file: any, callback: any) => {
	let reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = function () {
		callback(reader.result);
	};
	reader.onerror = function (error) {};
};
export const safeObject = (obj) => {
	if (typeof obj !== 'object' || obj === null) {
		return {};
	}
	for (let prop in obj) {
		if (obj.hasOwnProperty(prop) && typeof obj[prop] !== 'boolean') {
			if (obj[prop] === null || obj[prop] === undefined || obj[prop] === '') {
				delete obj[prop]; // Remove property if value is null, undefined, or empty string
			}
		}
	}
	return obj;
};
interface IOption {
	value: string;
	label: string;
}
export const concatSelectOptions = (
	option: IOption,
	options: IOption[]
): IOption[] => {
	console.log({ option, options });

	if (!option.hasOwnProperty('value') && !option.hasOwnProperty('label'))
		return options;
	const newOptions = options?.filter((x) => x.value !== option.value) || [];
	newOptions.push(option);
	return newOptions;
};

export const currencyFormat = (price: number): string => {
	return new Intl.NumberFormat('en-MY', {
		style: 'currency',
		currency: 'MYR',
	}).format(price);
};
export function getDuration(start, end) {
	const duration = Math.abs(end - start);
	const hours = Math.floor(duration / 3600000);
	const minutes = Math.floor((duration % 3600000) / 60000);
	return `${hours}h ${minutes}m`;
}
