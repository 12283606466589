import WithDrawalDetailsPage from './WithDrawalDetailsPage';
import WithDrawalPage from './WithDrawalPage';

export const WithDrawalRoutes = [
	{ path: '', element: <WithDrawalPage /> },
	{
		path: 'details/:id',
		element: <WithDrawalDetailsPage />,
	},
];
