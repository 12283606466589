import CarsPage from './CarsPage';
import CreateCarsPage from './CreateCarsPage';
import UpdateCarsPage from './UpdateCarsPage';

export const CarsRoutes = [
	{ path: '', element: <CarsPage /> },
	{ path: 'create', element: <CreateCarsPage /> },
	{
		path: 'update/:id',
		element: <UpdateCarsPage />,
	},
];
