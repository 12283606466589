import { Button, Form, Input, Switch, Upload, message } from 'antd';
import React, { useState } from 'react';

import { ApiServices } from '../../../services/api.service';
import { ENV } from '../../../ENV';
import { PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { queryClient } from '../../../config';
import { useMutation } from 'react-query';

interface IFProps {
	onSuccess?: () => void;
}
const CreateCategory: React.FC<IFProps> = ({ onSuccess }) => {
	const [form] = Form.useForm();

	const createCategoryMutation = useMutation(ApiServices.createCcategory, {
		onSuccess: (data) => {
			message.success('Category created successfully');
			form.resetFields();
			queryClient.invalidateQueries(`${ApiServices.filterCategories.name}`);
			if (onSuccess) onSuccess();
		},
	});
	const [files, setFiles] = useState<any>({
		image: '',
	});
	const onFinish = (values: any) => {
		const payload = {
			...values,
			image: values?.image?.file?.response?.payload?.links[0],
		};
		createCategoryMutation.mutate(payload);
	};
	return (
		<>
			<Form
				form={form}
				layout='vertical'
				name='basic'
				initialValues={{
					title: '',
					image: '',
					description: '',
					isActive: false,
				}}
				onFinish={onFinish}
				autoComplete='off'
			>
				<Form.Item
					className='flex justify-center items-center'
					label='Image'
					name='image'
					valuePropName='image'
					rules={[{ required: true, message: 'Please input Image!' }]}
				>
					<Upload
						accept='image/*'
						onChange={(info) => {
							if (info?.file.status === 'done') {
								setFiles({
									image: info?.file,
								});
							}
							if (info?.file.status === 'removed') {
								setFiles({
									image: null,
								});
							}
						}}
						listType='picture-card'
						action={ENV.IMAGE_UPLOAD_END_POINT}
						locale={{ uploading: 'Uploading...' }}
						customRequest={(options: any) => {
							const data = new FormData();
							data.append('files', options.file);
							fetch(options.action, {
								method: 'POST',
								body: data,
							})
								.then((res) => res.json())
								.then((res) => {
									options.onSuccess(res, options.file);
								})
								.catch((error) => {
									options.onError(error);
								});
						}}
					>
						{!files.image && (
							<div>
								<PlusOutlined />
								<div style={{ marginTop: 8 }}>Upload</div>
							</div>
						)}
					</Upload>
				</Form.Item>
				<Form.Item
					label='Title'
					name='title'
					rules={[
						{
							required: true,
							message: 'Please input your category title!',
						},
					]}
				>
					<Input placeholder='Title' />
				</Form.Item>
				<Form.Item
					label='Description'
					name='description'
					rules={[
						{
							required: true,
							message: 'Please input your category description!',
						},
					]}
				>
					<TextArea rows={4} placeholder='Description' />
				</Form.Item>
				<Form.Item label='Status' name='isActive' valuePropName='checked'>
					<Switch />
				</Form.Item>
				<Form.Item className='flex justify-end'>
					<Button
						type='primary'
						loading={createCategoryMutation.isLoading}
						htmlType='submit'
					>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};
export default CreateCategory;
