import { Button, Form, Input, Spin, Switch, Upload, message } from 'antd';
import { useMutation, useQuery } from 'react-query';

import { ApiServices } from '../../../services/api.service';
import { ENV } from '../../../ENV';
import { PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { queryClient } from '../../../config';
import { useState } from 'react';

interface IFProps {
	id?: string;
	onSuccess?: () => void;
}
const CategoryUpdate: React.FC<IFProps> = ({ id, onSuccess }) => {
	const [form] = Form.useForm();
	const [files, setFiles] = useState<any>({
		image: '',
	});
	const [apploading, setApploading] = useState(true);
	const categoryQuery = useQuery({
		queryKey: [`${ApiServices.categoryById.name}`, id],
		queryFn: () => ApiServices.categoryById(id),
		enabled: !!id,
		onSuccess: (data: any) => {
			form.setFieldsValue({
				title: data?.data?.payload?.title,
				description: data?.data?.payload?.description,
				isActive: data?.data?.payload?.isActive,
				image: data?.data?.payload?.image,
			});
			if (data?.data?.payload?.image) {
				setFiles([
					{
						uid: '31231221',
						name: ' im21g.id',
						status: 'done',
						url: `${data?.data?.payload?.image}`,
						urlPath: `${data?.data?.payload?.image}`,
						thumbUrl: `${data?.data?.payload?.image}`,
					},
				]);
			}
			setApploading(false);
		},
	});
	const categoryUpdateMutation = useMutation(ApiServices.updateCategory, {
		onSuccess: (data) => {
			message.success('Category updated successfully');
			queryClient.invalidateQueries(`${ApiServices.filterCategories.name}`);
			onSuccess && onSuccess();
		},
	});
	const onSubmit = (values: any) => {
		categoryUpdateMutation.mutate({
			data: {
				...values,
				image: files[0]?.urlPath,
			},
			id,
		});
	};
	return (
		<Form
			form={form}
			layout='vertical'
			name='basic'
			onFinish={onSubmit}
			initialValues={{
				title: '',
				description: '',
				isActive: false,
				image: '',
			}}
			autoComplete='off'
		>
			{categoryQuery.isLoading || apploading ? (
				<Spin className='flex justify-center'></Spin>
			) : (
				<>
					<Form.Item
						className='flex justify-center items-center'
						label='Image'
						name='image'
						valuePropName='image'
						rules={[{ required: true, message: 'Please input Image!' }]}
					>
						<Upload
							accept='image/*'
							onChange={(e) => {
								if (e.file.status === 'removed') {
									setFiles([]);
								}
								if (e.file.status === 'done') {
									setFiles([
										{
											...e.file,
											uid: e.file.uid,
											urlPath: e.file.response?.payload?.links[0],
										},
									]);
								}
							}}
							defaultFileList={files}
							maxCount={1}
							multiple={false}
							listType='picture-card'
							action={ENV.IMAGE_UPLOAD_END_POINT}
							locale={{ uploading: 'Uploading...' }}
							customRequest={(options: any) => {
								const data = new FormData();
								data.append('files', options.file);
								fetch(options.action, {
									method: 'POST',
									body: data,
								})
									.then((res) => res.json())
									.then((res) => {
										options.onSuccess(res, options.file);
									})
									.catch((error) => {
										options.onError(error);
									});
							}}
						>
							{!files.length && (
								<div>
									<PlusOutlined />
									<div style={{ marginTop: 8 }}>Upload</div>
								</div>
							)}
						</Upload>
					</Form.Item>
					<Form.Item
						label='Title'
						name='title'
						rules={[
							{
								required: true,
								message: 'Please input your category title!',
							},
						]}
					>
						<Input placeholder='Title' />
					</Form.Item>
					<Form.Item
						label='Description'
						name='description'
						rules={[
							{
								required: true,
								message: 'Please input your category description!',
							},
						]}
					>
						<TextArea rows={4} placeholder='Description' />
					</Form.Item>
					<Form.Item label='Status' name={'isActive'} valuePropName='checked'>
						<Switch />
					</Form.Item>
				</>
			)}
			<Form.Item className='flex justify-end'>
				<Button
					type='primary'
					loading={categoryUpdateMutation.isLoading}
					htmlType='submit'
				>
					Update
				</Button>
			</Form.Item>
		</Form>
	);
};
export default CategoryUpdate;
