import { Button, Form, Modal, Select, Switch, Table, message } from 'antd';
import {
	useBodiesQuery,
	useBodyStatusMutation,
	useDeleteBodyMutation,
} from '../../services/quries';

import { ApiServices } from '../../services/api.service';
import { AppLayout } from '../../themes/AppLayout';
import { ColumnsType } from 'antd/lib/table';
import { IFilterUsersRequest } from '../../interfaces/request.interfaces';
import Search from 'antd/es/input/Search';
import moment from 'moment';
import { queryClient } from '../../config';
import { toSafeArray } from '../../utils/util-function';
import { useDebounceFn } from 'ahooks';
import { useNavigate } from 'react-router';
import { useState } from 'react';

const BodiesPage = () => {
	const navigate = useNavigate();
	const [options, setOptions] = useState<IFilterUsersRequest>({
		page: 1,
		take: 10,
		searchTerm: '',
		isActive: null,
		type: '',
	});
	const { page, take } = options;
	const userStatusMutation = useBodyStatusMutation({
		config: {
			onSuccess: (data) => {
				message.success('Status updated successfully');
				queryClient.invalidateQueries(`${ApiServices.filterUsers.name}`);
			},
		},
	});
	const userDeleteMutation = useDeleteBodyMutation({
		config: {
			onSuccess: (data) => {
				message.success('User deleted successfully');
				queryClient.invalidateQueries(`${ApiServices.filterUsers.name}`);
			},
		},
	});
	const bodiesQuery = useBodiesQuery({
		options: options,
	});
	const onUpdateStatus = (item) => {
		Modal.confirm({
			title: 'Are you sure you want to update status?',
			onOk: () =>
				userStatusMutation.mutate({
					id: item?.id,
					data: {
						isActive: !item?.isActive,
					},
				}),
		});
	};
	const onUserDelete = (item) => {
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => userDeleteMutation.mutate(item?.id),
		});
	};
	const { run } = useDebounceFn(
		(e) => {
			setOptions({
				...options,
				searchTerm: e.target.value,
			});
		},
		{
			wait: 500,
		}
	);
	const columns: ColumnsType<any> = [
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			render: (text) => <a href='/'>{text}</a>,
		},
		{
			title: 'Is Active',
			key: 'isActive',
			dataIndex: 'isActive',
			render: (item: any) => {
				return (
					<Switch
						checked={item?.isActive}
						onChange={() => {
							onUpdateStatus(item);
						}}
					/>
				);
			},
		},
		{
			title: 'Created At',
			key: 'createdAt',
			dataIndex: 'createdAt',
		},
		{
			title: 'Action',
			key: 'action',
			align: 'right',
			render: (record) => (
				<div className='flex justify-end'>
					<Button
						type='primary'
						className='mr-2 cursor-pointer'
						size='small'
						onClick={() => {
							navigate(`/bodies/update/${record?.id}`);
						}}
					>
						Update
					</Button>
					<Button
						type='primary'
						className='mr-2'
						size='small'
						danger
						onClick={() => onUserDelete(record)}
					>
						Delete
					</Button>
				</div>
			),
		},
	];
	const data: any[] = toSafeArray(bodiesQuery?.data?.data?.payload)?.map(
		(item: any) => ({
			key: item?.id,
			id: item?.id,
			title: item?.title || 'N/A',
			isActive: item,
			createdAt: moment(item?.createdAt).format('DD/MM/YYYY') || 'N/A',
			action: item,
		})
	);
	return (
		<AppLayout
			pageTitle='Users Management'
			loading={
				userStatusMutation.isLoading ||
				bodiesQuery.isLoading ||
				userDeleteMutation.isLoading
			}
		>
			<Form
				layout='vertical'
				className='flex space-x-4 mb-2 bg-white p-3 rounded-md items-center'
			>
				<Form.Item label='Search'>
					<Search
						placeholder='Search ...'
						onChange={run}
						style={{ width: 200 }}
					/>
				</Form.Item>
				<Form.Item label='Type'>
					<Select
						defaultValue={'ALL'}
						style={{ width: 120 }}
						onChange={(e: any) => {
							setOptions({
								...options,
								type: e,
							});
						}}
						options={[
							{ value: 'ADMIN', label: 'ADMIN' },
							{ value: 'INDIVIDUAL', label: 'INDIVIDUAL' },
							{ value: 'ALL', label: '' },
						]}
					/>
				</Form.Item>
				<Form.Item label='Status'>
					<Select
						defaultValue={'ALL'}
						style={{ width: 120 }}
						onChange={(e: any) => {
							setOptions({
								...options,
								isActive: e === '' ? null : e === 'Active' ? true : false,
							});
						}}
						options={[
							{ value: 'Active', label: 'ACTIVE' },
							{ value: 'InActive', label: 'IN ACTIVE' },
							{ value: '', label: 'ALL' },
						]}
					/>
				</Form.Item>
				<div className='flex-1'>
					<Button
						type='primary'
						className='float-right'
						onClick={() => navigate('/bodies/create')}
					>
						Create User
					</Button>
				</div>
			</Form>
			<Table
				columns={columns}
				dataSource={data}
				pagination={{
					current: page,
					pageSize: take,
					total: bodiesQuery?.data?.data?.total,
					onChange(page, pageSize) {
						setOptions({
							...options,
							page,
							take: pageSize,
						});
					},
				}}
				scroll={{ x: 1300 }}
			/>
		</AppLayout>
	);
};
export default BodiesPage;
