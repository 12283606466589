import { Button, Form, Input, Spin, Switch, Upload } from 'antd';
import React, { useEffect, useState } from 'react';

import { ENV } from '../../../ENV';
import { PlusOutlined } from '@ant-design/icons';

interface IFProps {
	onSubmit: (value: any) => void;
	isLoading?: boolean;
	initialValues?: {
		title: string;
		image: string;
		isActive: boolean;
	};
}
const BrandForm: React.FC<IFProps> = ({
	onSubmit,
	initialValues,
	isLoading,
}) => {
	const [form] = Form.useForm();
	const [appLoading, setAppLoading] = useState(true);
	const [files, setFiles] = useState<any>([]);
	const onFinish = (values: any) => {
		const payload = {
			...values,
			image: files[0]?.urlPath,
		};
		if (onSubmit) {
			onSubmit(payload);
			form.resetFields();
		}
	};
	useEffect(() => {
		if (initialValues) {
			form.setFieldsValue(initialValues);
		}
		if (initialValues?.image) {
			setFiles([
				{
					uid: '31231221',
					name: ' im21g.id',
					status: 'done',
					url: `${initialValues.image}`,
					urlPath: `${initialValues.image}`,
					thumbUrl: `${initialValues.image}`,
				},
			]);
		}

		setTimeout(() => {
			setAppLoading(false);
		}, 2500);
		return () => {
			form.resetFields();
		};
	}, [
		initialValues.image,
		initialValues.title,
		initialValues.isActive,
		initialValues,
		form,
	]);
	return (
		<Form
			form={form}
			layout='vertical'
			name='basic'
			initialValues={{
				title: '',
				image: '',
				isActive: false,
			}}
			onFinish={onFinish}
			autoComplete='off'
		>
			{!appLoading ? (
				<Form.Item
					className='flex justify-center items-center'
					label='Image'
					name='image'
					valuePropName='image'
					rules={[{ required: true, message: 'Please input Image!' }]}
				>
					<Upload
						accept='image/*'
						onChange={(e) => {
							if (e.file.status === 'removed') {
								setFiles([]);
							}
							if (e.file.status === 'done') {
								setFiles([
									{
										...e.file,
										uid: e.file.uid,
										urlPath: e.file.response?.payload?.links[0],
									},
								]);
							}
						}}
						defaultFileList={files}
						maxCount={1}
						listType='picture-card'
						action={ENV.IMAGE_UPLOAD_END_POINT}
						locale={{ uploading: 'Uploading...' }}
						customRequest={(options: any) => {
							const data = new FormData();
							data.append('files', options.file);
							fetch(options.action, {
								method: 'POST',
								body: data,
							})
								.then((res) => res.json())
								.then((res) => {
									options.onSuccess(res, options.file);
								})
								.catch((error) => {
									options.onError(error);
								});
						}}
					>
						{!files.length && (
							<div>
								<PlusOutlined />
								<div style={{ marginTop: 8 }}>Upload</div>
							</div>
						)}
					</Upload>
				</Form.Item>
			) : (
				<div className='h-56 w-56 flex justify-center items-center'>
					<Spin></Spin>
				</div>
			)}
			<Form.Item
				label='Title'
				name='title'
				rules={[
					{
						required: true,
						message: 'Please input your Brand title!',
					},
				]}
			>
				<Input placeholder='Title' />
			</Form.Item>
			<Form.Item label='Status' name='isActive' valuePropName='checked'>
				<Switch />
			</Form.Item>
			<Form.Item className='flex justify-end'>
				<Button type='primary' loading={isLoading || false} htmlType='submit'>
					Submit
				</Button>
			</Form.Item>
		</Form>
	);
};
export default BrandForm;
