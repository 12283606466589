import { Outlet, useRoutes } from 'react-router-dom';

import { BodiesRoutes } from '../features/body';
import { BrandRoutes } from '../features/brand';
import { CarsRoutes } from '../features/cars';
import { CategoryRoutes } from '../features/category';
import { DashboardRoutes } from '../features/dashboard';
import DefaultDashboardPage from '../features/dashboard/routes/DefaultDashboardPage';
import NotFound from '../components/NotFound';
import { ReservationRoutes } from '../features/reservations';
import { UsersRoutes } from '../features/users';
import { WithDrawalRoutes } from '../features/withdrawal';

const App = () => {
	let pathName = window.location.pathname;
	return (
		<>
			{pathName === '/' ? <DefaultDashboardPage /> : ''}
			<Outlet />
		</>
	);
};
const ProtectedRoutes = () => {
	const routes = [
		{
			path: 'dashboard',
			children: DashboardRoutes,
		},
		{
			path: 'users',
			children: UsersRoutes,
		},
		{
			path: 'category',
			children: CategoryRoutes,
		},
		{
			path: 'brand',
			children: BrandRoutes,
		},
		{
			path: 'cars',
			children: CarsRoutes,
		},
		{
			path: 'bodies',
			children: BodiesRoutes,
		},
		{
			path: 'reservation',
			children: ReservationRoutes,
		},
		{
			path: 'withdrawal',
			children: WithDrawalRoutes,
		},
	];
	return useRoutes([
		{
			path: '',
			element: <App />,
			children: routes,
		},
		{
			path: '*',
			element: <NotFound />,
		},
	]);
};
export default ProtectedRoutes;
