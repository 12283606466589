import { Button, Card, Form, Input, message } from 'antd';
import {
	useBodyUpdateMutation,
	useFindBodiesByIdQuery,
} from '../../services/quries';
import { useNavigate, useParams } from 'react-router';

import { AppLayout } from '../../themes';
import { useForm } from 'antd/es/form/Form';

const UpdateBodyPage = () => {
	const [form] = useForm();
	const route = useParams();
	const navigate = useNavigate();
	const findBodiesByIdQuery = useFindBodiesByIdQuery({
		id: route?.id,
		config: {
			onSuccess: (data) => {
				form.setFieldsValue({
					title: data?.data.payload.title,
				});
			},
		},
	});
	const bodyUpdateMutation = useBodyUpdateMutation({
		config: {
			onSuccess: () => {
				message.success('Body Updated successfully');
				navigate('/bodies');
			},
		},
	});
	const onFinish = (values: any) => {
		bodyUpdateMutation.mutate({
			id: route?.id,
			data: values,
		});
	};
	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};
	return (
		<AppLayout
			pageTitle='Update Body'
			loading={bodyUpdateMutation.isLoading || findBodiesByIdQuery.isLoading}>
			<Card>
				<Form
					form={form}
					name='basic'
					style={{ maxWidth: 600 }}
					initialValues={{ title: '' }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					layout='vertical'
					autoComplete='off'>
					<Form.Item
						label='Title'
						name='title'
						rules={[{ required: true, message: 'Please input your title!' }]}>
						<Input />
					</Form.Item>
					<Button
						type='primary'
						htmlType='submit'>
						Submit
					</Button>
				</Form>
			</Card>
		</AppLayout>
	);
};
export default UpdateBodyPage;
