export * from './Paths';

export enum RentCountPer {
	DAILY = 'DAILY',
	HOURLY = 'HOURLY',
}
export enum CarStatus {
	PENDING = 'PENDING',
	DRAFT = 'DRAFT',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
}
export enum WithdrawalRequestStatus {
	PENDING = 'PENDING',
	REJECTED = 'REJECTED',
	COMPLETED = 'COMPLETED',
	PROCESSING = 'PROCESSING',
}
