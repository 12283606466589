import { Button, Card, Form, Input, message } from 'antd';

import { AppLayout } from '../../themes';
import { useCreateBodyMutation } from '../../services/quries';
import { useNavigate } from 'react-router';

const CreateBodyPage = () => {
	const navigate = useNavigate();
	const createBodyMutation = useCreateBodyMutation({
		config: {
			onSuccess: () => {
				message.success('Body created successfully');
				navigate('/bodies');
			},
		},
	});
	const onFinish = (values: any) => {
		createBodyMutation.mutate(values);
	};
	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};
	return (
		<AppLayout pageTitle='Create Body' loading={createBodyMutation.isLoading}>
			<Card>
				<Form
					name='basic'
					style={{ maxWidth: 600 }}
					initialValues={{ title: '' }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					layout='vertical'
					autoComplete='off'
				>
					<Form.Item
						label='Title'
						name='title'
						rules={[{ required: true, message: 'Please input your title!' }]}
					>
						<Input />
					</Form.Item>
					<Button type='primary' htmlType='submit'>
						Submit
					</Button>
				</Form>
			</Card>
		</AppLayout>
	);
};
export default CreateBodyPage;
