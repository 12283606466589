import { Tag, TagProps } from 'antd';

import React from 'react';

interface IFProps extends TagProps {
	status: 'PENDING' | 'APPROVED' | 'REJECTED' | 'COMPLETED' | 'PROCESSING';
	children: React.ReactNode;
}
const ShowStatus: React.FC<IFProps> = ({ status, children, ...rest }) => {
	const showStatus = () => {
		switch (status) {
			case 'PENDING':
				return (
					<Tag
						color='orange'
						{...rest}
						style={{ cursor: 'pointer' }}
						key={status}
					>
						{children}
					</Tag>
				);
			case 'APPROVED':
				return (
					<Tag
						{...rest}
						style={{ cursor: 'pointer' }}
						color='green'
						key={status}
					>
						{children}
					</Tag>
				);
			case 'REJECTED':
				return (
					<Tag {...rest} style={{ cursor: 'pointer' }} color='red' key={status}>
						{children}
					</Tag>
				);
			case 'COMPLETED':
				return (
					<Tag
						{...rest}
						style={{ cursor: 'pointer' }}
						color='blue'
						key={status}
					>
						{children}
					</Tag>
				);
			case 'PROCESSING':
				return (
					<Tag
						{...rest}
						style={{ cursor: 'pointer' }}
						color='purple'
						key={status}
					>
						{children}
					</Tag>
				);

			default:
				return (
					<Tag
						{...rest}
						style={{ cursor: 'pointer' }}
						color='default'
						key={status}
					>
						{children}
					</Tag>
				);
		}
	};
	return showStatus();
};
export default ShowStatus;
