import { Button, Card, Form, Input, Spin, Upload, message } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';

import { ApiServices } from '../../services/api.service';
import { AppLayout } from '../../themes/AppLayout';
import { ENV } from '../../ENV';
import { PlusOutlined } from '@ant-design/icons';
import { queryClient } from '../../config';
import { useState } from 'react';

const UpdateUserPage = () => {
	const [apploading, setApploading] = useState(true);
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const route = useParams();
	const userQuery = useQuery({
		queryKey: [`${ApiServices.userById.name}`, route?.id],
		queryFn: () => ApiServices.userById(route?.id),
		enabled: !!route?.id,
		onSuccess: (data: any) => {
			form.setFieldsValue({
				firstName: data?.data?.payload?.userInfo?.firstName,
				lastName: data?.data?.payload?.userInfo?.lastName,
				phone: data?.data?.payload?.userInfo?.phone,
				nid: data?.data?.payload?.userInfo?.nid,
				email: data?.data?.payload?.email,
				type: data?.data?.payload?.type,
			});
			if (data?.data?.payload?.userInfo?.selfie) {
				setSelfies([
					{
						uid: '31231221',
						name: ' im21g.id',
						status: 'done',
						url: data?.data?.payload?.userInfo?.selfie,
						urlPath: data?.data?.payload?.userInfo?.selfie,
						thumbUrl: data?.data?.payload?.userInfo?.selfie,
					},
				]);
			}
			if (data?.data?.payload?.userInfo?.nidFront) {
				setNidFront([
					{
						uid: '31221321',
						name: ' i2mg.id',
						status: 'done',
						url: data?.data?.payload?.userInfo?.nidFront,
						urlPath: data?.data?.payload?.userInfo?.nidFront,
						thumbUrl: data?.data?.payload?.userInfo?.nidFront,
					},
				]);
			}
			if (data?.data?.payload?.userInfo?.nidBack) {
				setNidBack([
					{
						uid: '312321',
						name: ' img.id',
						status: 'done',
						url: data?.data?.payload?.userInfo?.nidBack,
						urlPath: data?.data?.payload?.userInfo?.nidBack,
						thumbUrl: data?.data?.payload?.userInfo?.nidBack,
					},
				]);
			}
			if (data?.data?.payload?.userInfo?.driveLicenseFront) {
				setDriveLicenseFront([
					{
						uid: '32112321',
						name: ' im2g.id',
						status: 'done',
						url: data?.data?.payload?.userInfo?.driveLicenseFront,
						urlPath: data?.data?.payload?.userInfo?.driveLicenseFront,
						thumbUrl: data?.data?.payload?.userInfo?.driveLicenseFront,
					},
				]);
			}
			if (data?.data?.payload?.userInfo?.driveLicenseBack) {
				setDriveLicenseBack([
					{
						uid: '31212321',
						name: ' img21.id',
						status: 'done',
						url: data?.data?.payload?.userInfo?.driveLicenseBack,
						urlPath: data?.data?.payload?.userInfo?.driveLicenseBack,
						thumbUrl: data?.data?.payload?.userInfo?.driveLicenseBack,
					},
				]);
			}
			setApploading(false);
		},
	});
	const upadteUserMutation = useMutation(ApiServices.updateUser, {
		onSuccess: (data) => {
			message.success('User Updated successfully');
			queryClient.invalidateQueries(`${ApiServices.filterUsers.name}`);
			navigate('/users');
		},
	});
	const [selfies, setSelfies] = useState<any>([]);
	const [nidFront, setNidFront] = useState([]);
	const [nidBack, setNidBack] = useState([]);
	const [driveLicenseFront, setDriveLicenseFront] = useState([]);
	const [driveLicenseBack, setDriveLicenseBack] = useState([]);
	const onFinish = (values: any) => {
		const payload = {
			...values,
			selfie: selfies.length ? selfies[0]?.urlPath : null,
			driveLicenseBack: driveLicenseBack.length
				? driveLicenseBack[0]?.urlPath
				: null,
			driveLicenseFront: driveLicenseFront.length
				? driveLicenseFront[0]?.urlPath
				: null,
			nidBack: nidBack.length ? nidBack[0]?.urlPath : null,
			nidFront: nidFront.length ? nidFront[0]?.urlPath : null,
		};
		upadteUserMutation.mutate({
			data: payload,
			id: route?.id,
		});
	};
	return userQuery.isLoading || apploading ? (
		<Spin />
	) : (
		<AppLayout
			pageTitle='Update User'
			loading={upadteUserMutation.isLoading || userQuery.isLoading}
		>
			<Card>
				<Form
					form={form}
					layout='vertical'
					name='basic'
					initialValues={{
						firstName: '',
						lastName: '',
						phone: '',
						email: '',
						nid: '',
						selfie: '',
						nidFront: '',
						nidBack: '',
						driveLicenseFront: '',
						driveLicenseBack: '',
					}}
					onFinish={onFinish}
					autoComplete='off'
				>
					<div className='grid grid-cols-2 gap-7'>
						<div className=''>
							<Form.Item
								label='FirstName'
								name='firstName'
								rules={[
									{ required: true, message: 'Please input your firstName!' },
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								label='LastName'
								name='lastName'
								rules={[
									{ required: true, message: 'Please input your lastName!' },
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								label='Phone'
								name='phone'
								rules={[
									{ required: true, message: 'Please input your phone!' },
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								label='Email'
								name='email'
								rules={[
									{ required: true, message: 'Please input your email!' },
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								label='NID'
								name='nid'
								rules={[{ required: true, message: 'Please input your nid!' }]}
							>
								<Input />
							</Form.Item>
						</div>
						<div>
							<div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 gap-1'>
								<Form.Item label='Selfie' name='selfie' valuePropName='selfie'>
									<Upload
										accept='image/*'
										multiple={false}
										maxCount={1}
										defaultFileList={selfies}
										listType='picture-card'
										action={ENV.IMAGE_UPLOAD_END_POINT}
										locale={{ uploading: 'Uploading...' }}
										onChange={(e) => {
											if (e.file.status === 'removed') {
												setSelfies([]);
											}
											if (e.file.status === 'done') {
												setSelfies([
													{
														...e.file,
														uid: e.file.uid,
														urlPath: e.file.response?.payload?.links[0],
													},
												]);
											}
										}}
										customRequest={(options: any) => {
											const data = new FormData();
											data.append('files', options.file);
											fetch(options.action, {
												method: 'POST',
												body: data,
											})
												.then((res) => res.json())
												.then((res) => {
													options.onSuccess(res, options.file);
												})
												.catch((error) => {
													options.onError(error);
												});
										}}
									>
										{!selfies.length && (
											<div>
												<PlusOutlined />
												<div style={{ marginTop: 8 }}>Upload</div>
											</div>
										)}
									</Upload>
								</Form.Item>
								<Form.Item
									label='NID Front'
									name='nidFront'
									valuePropName='nidFront'
								>
									<Upload
										accept='image/*'
										multiple={false}
										maxCount={1}
										defaultFileList={nidFront}
										listType='picture-card'
										action={ENV.IMAGE_UPLOAD_END_POINT}
										locale={{ uploading: 'Uploading...' }}
										onChange={(e) => {
											if (e.file.status === 'removed') {
												setNidFront([]);
											}
											if (e.file.status === 'done') {
												setNidFront([
													{
														...e.file,
														uid: e.file.uid,
														urlPath: e.file.response?.payload?.links[0],
													},
												]);
											}
										}}
										customRequest={(options: any) => {
											const data = new FormData();
											data.append('files', options.file);
											fetch(options.action, {
												method: 'POST',
												body: data,
											})
												.then((res) => res.json())
												.then((res) => {
													options.onSuccess(res, options.file);
												})
												.catch((error) => {
													options.onError(error);
												});
										}}
									>
										{!nidFront.length && (
											<div>
												<PlusOutlined />
												<div style={{ marginTop: 8 }}>Upload</div>
											</div>
										)}
									</Upload>
								</Form.Item>
								<Form.Item
									label='NID Back'
									name='nidBack'
									valuePropName='nidBack'
								>
									<Upload
										accept='image/*'
										multiple={false}
										maxCount={1}
										defaultFileList={nidBack}
										listType='picture-card'
										action={ENV.IMAGE_UPLOAD_END_POINT}
										locale={{ uploading: 'Uploading...' }}
										onChange={(e) => {
											if (e.file.status === 'removed') {
												setNidBack([]);
											}
											if (e.file.status === 'done') {
												setNidBack([
													{
														...e.file,
														uid: e.file.uid,
														urlPath: e.file.response?.payload?.links[0],
													},
												]);
											}
										}}
										customRequest={(options: any) => {
											const data = new FormData();
											data.append('files', options.file);
											fetch(options.action, {
												method: 'POST',
												body: data,
											})
												.then((res) => res.json())
												.then((res) => {
													options.onSuccess(res, options.file);
												})
												.catch((error) => {
													options.onError(error);
												});
										}}
									>
										{!nidBack.length && (
											<div>
												<PlusOutlined />
												<div style={{ marginTop: 8 }}>Upload</div>
											</div>
										)}
									</Upload>
								</Form.Item>
								<Form.Item
									label='Drive License Front'
									name='driveLicenseFront'
									valuePropName='driveLicenseFront'
								>
									<Upload
										accept='image/*'
										multiple={false}
										maxCount={1}
										defaultFileList={driveLicenseFront}
										listType='picture-card'
										action={ENV.IMAGE_UPLOAD_END_POINT}
										locale={{ uploading: 'Uploading...' }}
										onChange={(e) => {
											if (e.file.status === 'removed') {
												setDriveLicenseFront([]);
											}
											if (e.file.status === 'done') {
												setDriveLicenseFront([
													{
														...e.file,
														uid: e.file.uid,
														urlPath: e.file.response?.payload?.links[0],
													},
												]);
											}
										}}
										customRequest={(options: any) => {
											const data = new FormData();
											data.append('files', options.file);
											fetch(options.action, {
												method: 'POST',
												body: data,
											})
												.then((res) => res.json())
												.then((res) => {
													options.onSuccess(res, options.file);
												})
												.catch((error) => {
													options.onError(error);
												});
										}}
									>
										{!driveLicenseFront.length && (
											<div>
												<PlusOutlined />
												<div style={{ marginTop: 8 }}>Upload</div>
											</div>
										)}
									</Upload>
								</Form.Item>
								<Form.Item
									label='Drive License Back'
									name='driveLicenseBack'
									valuePropName='driveLicenseBack'
								>
									<Upload
										accept='image/*'
										multiple={false}
										maxCount={1}
										defaultFileList={driveLicenseBack}
										listType='picture-card'
										action={ENV.IMAGE_UPLOAD_END_POINT}
										locale={{ uploading: 'Uploading...' }}
										onChange={(e) => {
											if (e.file.status === 'removed') {
												setDriveLicenseBack([]);
											}
											if (e.file.status === 'done') {
												setDriveLicenseBack([
													{
														...e.file,
														uid: e.file.uid,
														urlPath: e.file.response?.payload?.links[0],
													},
												]);
											}
										}}
										customRequest={(options: any) => {
											const data = new FormData();
											data.append('files', options.file);
											fetch(options.action, {
												method: 'POST',
												body: data,
											})
												.then((res) => res.json())
												.then((res) => {
													options.onSuccess(res, options.file);
												})
												.catch((error) => {
													options.onError(error);
												});
										}}
									>
										{!driveLicenseBack.length && (
											<div>
												<PlusOutlined />
												<div style={{ marginTop: 8 }}>Upload</div>
											</div>
										)}
									</Upload>
								</Form.Item>
							</div>
						</div>
					</div>
					<Form.Item>
						<Button
							loading={upadteUserMutation.isLoading}
							type='primary'
							htmlType='submit'
						>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</AppLayout>
	);
};
export default UpdateUserPage;
